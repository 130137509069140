function FreeDownload() {
  return (
    <main className="Free-container">
      <div className="cwa-title">Crypto Wallet Video</div>
      <div className="cwa-subtitle">
        Skyclones' Crypto Wallet also offers a video feature, providing you with
        a visual guide on how to use our wallet and manage your digital assets.
        Our video tutorial covers everything from setting up your wallet and
        sending and receiving payments to managing your portfolio and keeping
        your funds secure. With our video feature, you can get up to speed on
        the ins and outs of cryptocurrency and our wallet in just a few minutes,
        with clear and concise instructions that guide you through every step of
        the process. Whether you're a seasoned cryptocurrency investor or new to
        the world of digital assets, our video feature makes it easy to manage
        your funds and get the most out of our wallet.
      </div>
      <iframe
        title="free"
        width="0px"
        height="600px"
        src={process.env.REACT_APP_YOUTUBE_CRYPTO_URL}
      ></iframe>
    </main>
  );
}

export default FreeDownload;
