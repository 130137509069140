import { EC_PANEL_ICON } from "../../assets";

function RBWebPanel() {
  return (
    <main className="WebPanel-container">
      <div className="cwa-title">Food Recipe Book Web Panel</div>
      <div className="cwa-subtitle">
        With our Food Recipe Book Readymade Script, users can also upload their
        own recipes and share them with other users. The app also features a
        rating and review system, where users can rate and review the recipes
        they try.
      </div>
      <img src={EC_PANEL_ICON} alt="icon" className="webpanel-img" />
    </main>
  );
}

export default RBWebPanel;
