import { useEffect, useRef, useState } from "react";
import {
  DF_IMG,
  FEEDBACK_VECTOR,
  NEXT_ICON,
  PREVIOUS_ICON,
} from "../../assets";

function Feedback() {
  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  // eslint-disable-next-line
  const [scrolEnd, setscrolEnd] = useState(false);

  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  useEffect(() => {
    if (
      scrl.current &&
      scrl?.current?.scrollWidth === scrl?.current?.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
    return () => {};
  }, [scrl?.current?.scrollWidth, scrl?.current?.offsetWidth]);
  return (
    <main className="Feedback-container">
      <section className="Feedback-flex">
        <div className="Feedback-flex-col">
          <span className="Feedback-smtitle">Feedback</span>
          <span className="Feedback-title">
            Client <br />
            Testimonials
          </span>
        </div>
        <div className="Feedback_position">
          <img src={FEEDBACK_VECTOR} alt="vector" className="feedback_vector" />
          <div className="Feedback_profiel">
            <div
              className="Feedback_position_scroll"
              ref={scrl}
              onScroll={scrollCheck}
            >
              {FeedbackData.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="Feedback_profiel-flex">
                      <div className="Feedback_profiel_img">
                        <img src={item?.IMG} alt="img" />
                      </div>
                      <div>
                        <div className="Feedback_profiel_name">
                          {item?.Name}
                        </div>
                        <div className="Feedback_profiel_project">
                          {item?.Role}
                        </div>
                      </div>
                    </div>
                    <div className="Feedback_profiel_info">{item?.Desc}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="Arrow_icon">
          <img
            src={PREVIOUS_ICON}
            alt="icon"
            style={{ marginRight: "20px" }}
            onClick={() => slide(-410)}
          />
          <img src={NEXT_ICON} alt="icon" onClick={() => slide(+410)} />
        </div>
      </section>
    </main>
  );
}

export default Feedback;

const FeedbackData = [
  {
    IMG: DF_IMG,
    Name: "John D",
    Role: " On-demand App",
    Desc: "I couldn't be happier with the readymade script I purchased from Skyclones. It was easy to customize and it saved me so much time and money. I would definitely recommend Skyclones to anyone looking to create a great mobile app quickly and cost-effectively.",
  },
  {
    IMG: DF_IMG,
    Name: "Emily L",
    Role: " On-demand App",
    Desc: ".Skyclones' UI controls are amazing! They've helped me create a beautiful and functional mobile app in no time. Their support team was also incredibly helpful and responsive.",
  },
  {
    IMG: DF_IMG,
    Name: "Michael K",
    Role: " On-demand App",
    Desc: "I worked with Skyclones on a custom application development project, and I was blown away by their professionalism and expertise. They really understood my vision and delivered an amazing product. I would definitely work with them again.",
  },
  {
    IMG: DF_IMG,
    Name: "Sarah T",
    Role: " On-demand App",
    Desc: "Skyclones' e-commerce to mobile app converter is a game changer! It's so easy to use and it's helped me increase sales and customer engagement. I'm so glad I found Skyclones.",
  },
  {
    IMG: DF_IMG,
    Name: " David P",
    Role: " On-demand App",
    Desc: "I had a great experience with Skyclones' dedicated support team. They were able to answer all of my questions and help me optimize my mobile app. I felt like they really cared about my success.",
  },
];
