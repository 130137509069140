import React from 'react'
import { Link } from 'react-router-dom'
import { CARRENTAL_VECTOR, WEB_ICON } from '../../assets'

function CarRental() {
    return (
        <main className="Carrental-container Carrenatal_center_BG">
            <section className="Instasoft-flex">
                <div className="Instasoft-flex-col">
                    <span className="Carrental-smtitle">
                        FREE CAR RENTAL READYMADE SCRIPT
                    </span>
                    <Link to="/instagram-clone" className="cryptowallet-title">
                        FREE CAR RENTAL READYMADE SCRIPT
                    </Link>
                    <span className="cryptowallet-sub-title">
                        Manage your car rental business efficiently with our web panel. Our user-friendly interface allows you to oversee bookings, monitor your fleet, and handle payments effortlessly.
                    </span>
                    <div className="cryptowallet-btn-text">
                        <span className="cryptowallet-btn-text_">Checkout Live on</span>
                        <div>
                            <Link to={process.env.REACT_APP_WEB_CAR_RENTAL_URL} target="_blank">
                                <button className="blue-btn-small">
                                    <div className="btn_align">
                                        <img src={WEB_ICON} alt="icon" className="btn_img" />
                                        <span>Web</span>
                                    </div>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="btn-flex">
                        {/* <Link
                            to={process.env.REACT_APP_PLAY_STORE_INSTA_URL}
                            target="_blank"
                        >
                            <button className="black-btn-small">Free Download</button>
                        </Link> */}
                        <Link to={process.env.REACT_APP_BLOG_CAR_RENTAL_URL} target="_blank">
                            <button className="black-btn-small">Buy Now</button>
                        </Link>
                    </div>
                </div>
                <div className="Instasoft_img">
                    <img
                        src={CARRENTAL_VECTOR}
                        alt="vector"
                        className="cryptowallet-img"
                    />
                </div>
            </section>
        </main>
    )
}

export default CarRental