import { Link } from "react-router-dom";
import {
  ANDROID_ICON,
  APPLE_ICON,
  E_COMMERCE_STORE_VECTOR,
} from "../../assets";

function ResipeBook() {
  return (
    <main className="ECommercestore-container RB_about_BG">
      <section className="ECommercestore-flex">
        <div>
          <img
            src={E_COMMERCE_STORE_VECTOR}
            alt="vector"
            className="cryptowallet-img"
          />
        </div>
        <div className="ECommercestore-flex-col">
          <span className="ECommercestore-smtitle">
            Free Food Recipe Book Script
          </span>
          <Link to="/food-recipe-book" className="cryptowallet-title">
            Food Recipe Book Readymade Script
          </Link>
          <span className="cryptowallet-sub-title">
            Welcome to our Food Recipe Book Readymade Script, a one-stop
            solution for all food enthusiasts out there. This mobile application
            is designed to provide users with a comprehensive collection of
            recipes for all kinds of food items. From appetizers to desserts,
            you can find a wide range of recipes to satisfy your taste buds.
          </span>
          <div className="cryptowallet-btn-text">
            <span className="cryptowallet-btn-text_">Checkout Live on</span>
            <div>
              <Link to={process.env.REACT_APP_IOS_FOOD_RECIPE_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={APPLE_ICON} alt="icon" className="btn_img" />
                    <span>iOS</span>
                  </div>
                </button>
              </Link>
              <Link to={process.env.REACT_APP_PLAY_STORE_FOOD_RECIPE_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={ANDROID_ICON} alt="icon" className="btn_img" />
                    <span>Android</span>
                  </div>
                </button>
              </Link>
            </div>
          </div>
          <div className="btn-flex">
            <Link to={process.env.REACT_APP_PLAY_STORE_FOOD_RECIPE_URL} target="_blank">
              <button className="black-btn-small">Free Download</button>
            </Link>
            <button className="black-btn-small">
              <Link
                to={process.env.REACT_APP_BLOG_FOOD_RECIPE_URL}
                target="_blank"
              >
                Buy Now
              </Link>
            </button>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ResipeBook;
