import {
  T_ANDROID_ICON,
  T_IOS_ICON,
  T_KOTLIN_ICON,
  T_REACT_ICON,
  T_WEB_ICON,
} from "../../assets";

function ISTechnologies() {
  return (
    <main className="Technologies-container">
      <div className="WebPanel-title">Technologies</div>
      <div className="cwa-subtitle">
        The Instagram clone is built using the latest and most advanced
        technologies, ensuring a high-quality user experience. It uses a robust
        and scalable backend technology to handle user data and content
        management. The front-end is built using modern frameworks and libraries
        to ensure smooth navigation and responsiveness. The app also
        incorporates features like push notifications, geolocation, and social
        sharing to enhance user engagement.
      </div>
      <div>
        <div className="Technologies-wrapper">
          <div className="Technologies-box">
            <img src={T_ANDROID_ICON} alt="icon" />
            <span>Android</span>
          </div>
          <div className="Technologies-box">
            <img src={T_IOS_ICON} alt="icon" />
            <span>ios</span>
          </div>
          <div className="Technologies-box">
            <img src={T_KOTLIN_ICON} alt="icon" />
            <span>Kotlin</span>
          </div>
          <div className="Technologies-box">
            <img src={T_WEB_ICON} alt="icon" />
            <span>Web</span>
          </div>
          <div className="Technologies-box">
            <img src={T_REACT_ICON} alt="icon" />
            <span>React</span>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ISTechnologies;
