import React from 'react'

function VideoPlay({ videoPlay }) {
    return (
        <main className="BNB_Free-container" style={{ backgroundImage: `url(${videoPlay?.backgroundImage})` }}>
            <div className="cwa-title">{videoPlay?.title}</div>
            <div className="cwa-subtitle">
                {videoPlay?.subtitle}
            </div>
            <iframe
                title="BNB"
                width="0px"
                height="600px"
                src={videoPlay?.videoLink}
            ></iframe>
        </main>
    )
}

export default VideoPlay