import {
  DB_YOUR_DOCTOR_ICON,
  DB_BOOKING_ICON,
  DB_SETTING_ICON,
  DB_DEATILS_ICON,
  DB_MANAGE_ICON,
  DB_SUMMARY_ICON,
} from "../../assets";

function DBAboutFeatures() {
  return (
    <main className="DBAboutFeatures-container">
      <div className="AboutFeatures-title">About Features</div>
      <div>
        <div className="AboutFeatures-wrapper">
          {AboutFeaturesData.map((item, index) => {
            return (
              <div className="AboutFeatures-box" key={index}>
                <div className="AboutFeatures-card">
                  <div className="AboutFeatures-img-flex">
                    <div className="DBAboutFeatures-img-box">
                      <img
                        src={item?.IMG}
                        alt="icon"
                        className="BNBAboutFeatures-img_"
                      />
                    </div>
                    <div className="AboutFeatures-subtitle ">{item?.Title}</div>
                  </div>
                  <div className="AboutFeatures-info">{item?.describe}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
}
export default DBAboutFeatures;

const AboutFeaturesData = [
  {
    IMG: DB_YOUR_DOCTOR_ICON,
    Title: "Your Doctor",
    describe: `"Your Doctor" is a feature of the Doctor On Demand readymade script that allows patients to select and book appointments with their preferred doctors based on their specialties and availability. The feature enables patients to view the doctors' profiles, including their ratings, qualifications, and reviews, and choose the one that best suits their needs. This feature also ensures that patients receive personalized care and attention from doctors they trust, fostering a stronger doctor-patient relationship.`,
  },
  {
    IMG: DB_BOOKING_ICON,
    Title: "YOUR APPOINTMENT BOOKING",
    describe:
      "With our Doctor on Demand readymade script, you can easily book appointments with your preferred doctor from anywhere and at any time. You can select a date and time that suits you and book an appointment with just a few clicks. Our user-friendly interface makes it easy to manage your appointments and keep track of your upcoming appointments. No more waiting in long queues or struggling to get an appointment with your preferred doctor.",
  },
  {
    IMG: DB_DEATILS_ICON,
    Title: "PRESCRIPTION DETAILS",
    describe:
      "The Doctor On Demand Readymade Script includes a feature for prescription details, which makes it easier for doctors to keep track of their patient's prescriptions. Patients can view their prescription history, and doctors can easily update and manage their patient's prescriptions within the system. This ensures a more streamlined and efficient process for both doctors and patients.",
  },
  {
    IMG: DB_SUMMARY_ICON,
    Title: "Summary",
    describe:
      "The summary feature in the Doctor on Demand script provides a comprehensive summary of your medical history, including your diagnosis, treatment plan, medication history, and laboratory test results. This allows you to keep track of your health and make informed decisions about your care. You can easily access your summary at any time through the app or web panel.",
  },
  {
    IMG: DB_MANAGE_ICON,
    Title: "MANAGE YOUR SCHEDULE",
    describe:
      "The Doctor on Demand readymade script allows users to easily manage their schedule by providing a comprehensive booking system. Patients can view available time slots, select the most suitable appointment time and date, and book their appointment with just a few clicks. Doctors can view their upcoming appointments, confirm or reschedule appointments as necessary, and manage their availability in real-time. This feature makes the appointment scheduling process seamless and efficient for both patients and doctors.",
  },
  {
    IMG: DB_SETTING_ICON,
    Title: "SETTING UP YOUR AVAILABILITY",
    describe: `The "Setting up your availability" feature in our Doctor On Demand readymade script allows doctors to set their availability for appointments, making it easier for patients to book appointments with them. Doctors can update their availability status, specify the time slots for appointments, and update their schedules as needed. This feature provides more flexibility to doctors and ensures that patients can easily find a time to schedule an appointment that works for them.`,
  },
];
