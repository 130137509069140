import { Link } from "react-router-dom";
import {
  ANDROID_ICON,
  APPLE_ICON,
  APPOINTMENT_BOOKING_VECTOR,
  WEB_ICON,
  // WEB_ICON,
} from "../../assets";
// import Button from "../Button";

function AppointmentBooking() {
  return (
    <main className="appoibooking-container Appoi_Booking_center_BG">
      <section className="appoibooking-flex">
        <div className="appoibooking_img">
          <img
            src={APPOINTMENT_BOOKING_VECTOR}
            alt="vector"
            className="cryptowallet-img"
          />
        </div>
        <div className="appoibooking-flex-col">
          <span className="appoibooking-smtitle">
            Free Doctor on demand Script
          </span>
          <Link to="/doctor-on-demand" className="cryptowallet-title">
            Doctor on Demand
          </Link>
          <span className="cryptowallet-sub-title">
            Skyclones' Doctor on Demand appointment booking system is a
            convenient and easy-to-use solution for patients to schedule
            appointments with doctors. Available for Android, iOS, and web, our
            system simplifies the booking process and helps doctors manage their
            schedules more efficiently.Streamline your appointment booking
            process with Skyclones' Doctor on Demand appointment booking system
            and provide a better experience for your patients and doctors alike.
          </span>
          <div className="cryptowallet-btn-text">
            <span className="cryptowallet-btn-text_">Checkout Live on</span>
            <div>
              <Link to={process.env.REACT_APP_IOS_DOCTOR_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={APPLE_ICON} alt="icon" className="btn_img" />
                    <span>iOS</span>
                  </div>
                </button>
              </Link>
              <Link
                to={process.env.REACT_APP_PLAY_STORE_DOCTOR_URL}
                target="_blank"
              >
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={ANDROID_ICON} alt="icon" className="btn_img" />
                    <span>Android</span>
                  </div>
                </button>
              </Link>
              <Link to={process.env.REACT_APP_WEB_DOCTOR_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={WEB_ICON} alt="icon" className="btn_img" />
                    <span>Web</span>
                  </div>
                </button>
              </Link>
            </div>
          </div>
          <div className="btn-flex">
            <Link
              to={process.env.REACT_APP_PLAY_STORE_DOCTOR_URL}
              target="_blank"
            >
              <button className="black-btn-small">Free Download</button>
            </Link>
            <Link to={process.env.REACT_APP_BLOG_DOCTOR_URL} target="_blank">
              <button className="black-btn-small">Buy Now</button>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AppointmentBooking;
