import { Link } from "react-router-dom";
import { DB_MAIN_VECTOR } from "../../assets";
function DBCrateWallet() {
  return (
    <main className="DB_main-container">
      <section className="webapp-flex">
        <div className="webapp-flex-col">
          <span className="webapp-title">
            Doctor on Demand Readymade Script
          </span>
          <span className="webapp-sub-title">
            Our Doctor on Demand Readymade Script is a comprehensive solution
            for startups looking to establish an online appointment booking
            system for doctors. The script is compatible with web and mobile
            platforms, providing patients with the convenience of booking
            appointments from anywhere. With advanced features like real-time
            video consultations and secure patient data management, this script
            is a game-changer for the healthcare industry. Get your hands on
            this script today and launch your online medical appointment booking
            platform in no time!
          </span>
          <div className="btn-flex">
            <Link to={process.env.REACT_APP_BLOG_DOCTOR_URL} target="_blank">
              <button className="red-btn-small">
                <div className="btn_align">Buy Now</div>
              </button>
            </Link>
            <Link to={process.env.REACT_APP_PLAY_STORE_DOCTOR_URL} target="_blank">
              <button className="white-btn-small">
                <div className="btn_align">Free Download</div>
              </button>
            </Link>
          </div>
        </div>
        <div>
          <img src={DB_MAIN_VECTOR} alt="vector" className="cryptowallet-img" />
        </div>
      </section>
    </main>
  );
}

export default DBCrateWallet;
