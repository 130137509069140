import { DB_WEB_DASH } from "../../assets";

function DBWebPanel() {
  return (
    <main className="WebPanel-container">
      <div className="WebPanel-title">Doctor on demand script Web Panel</div>
      <div className="cwa-subtitle">
        Our Doctor on Demand readymade script is fully customizable and can be
        tailored to meet the specific needs of healthcare providers. We offer
        flexible pricing plans that can accommodate any budget, making it easy
        for healthcare providers to get started with our appointment booking
        system. With our script, healthcare providers can easily manage their
        appointments, patients and schedules, all while providing top-quality
        care to their patients.
      </div>
      <img src={DB_WEB_DASH} alt="icon" className="webpanel-img" />
    </main>
  );
}

export default DBWebPanel;
