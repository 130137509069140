import React from 'react'


function BusinessInfo({ businessInfo }) {
    return (
        <main className="Dedicated-container">
            <section className="Dedicated-flex">
                <div>
                    <img src={businessInfo?.vector} alt="vector" className="AndroidIOS-img" />
                </div>
                <div className="Dedicated-flex-col">
                    <span className="Dedicated-smtitle">Business</span>
                    <span className="cryptowallet-title">{businessInfo?.title}</span>
                    <span className="New-features-list lg:max-w-xl ">
                        {businessInfo?.subtitle}
                    </span>
                </div>
            </section>
        </main>
    )
}

export default BusinessInfo