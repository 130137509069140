import {
  T_ANDROID_ICON,
  T_IOS_ICON,
  T_KOTLIN_ICON,
  T_REACT_ICON,
  T_WEB_ICON,
} from "../../assets";

function DBTechnologies() {
  return (
    <main className="Technologies-container">
      <div className="WebPanel-title">Technologies</div>
      <div className="cwa-subtitle">
        The Doctor on Demand script utilizes modern and robust technologies such
        as WebRTC, which enables high-quality video and audio communication
        between doctors and patients in real-time. The script is built on a
        secure and reliable architecture that ensures the safety and privacy of
        patients' health data. Additionally, it offers seamless integration with
        popular payment gateways and third-party APIs, allowing for a
        hassle-free and efficient appointment booking and management process.
      </div>
      <div>
        <div className="Technologies-wrapper">
          <div className="Technologies-box">
            <img src={T_ANDROID_ICON} alt="icon" />
            <span>Android</span>
          </div>
          <div className="Technologies-box">
            <img src={T_IOS_ICON} alt="icon" />
            <span>ios</span>
          </div>
          <div className="Technologies-box">
            <img src={T_KOTLIN_ICON} alt="icon" />
            <span>Kotlin</span>
          </div>
          <div className="Technologies-box">
            <img src={T_WEB_ICON} alt="icon" />
            <span>Web</span>
          </div>
          <div className="Technologies-box">
            <img src={T_REACT_ICON} alt="icon" />
            <span>React</span>
          </div>
        </div>
      </div>
    </main>
  );
}

export default DBTechnologies;
