import { WEB_PANEL_VECTOR } from "../../assets";

function WebPanel() {
  return (
    <main className="WebPanel-container">
      <div className="cwa-title">Crypto Wallet Web Panel</div>
      <div className="cwa-subtitle">
        Skyclones' Crypto Wallet also provides a web panel, giving you access to
        your digital assets from anywhere with an internet connection. With our
        web panel, you can manage your cryptocurrency from your desktop or
        laptop computer, using a simple and intuitive interface that makes it
        easy to check your balances, send and receive payments, and track your
        transactions. Plus, with support for multiple cryptocurrencies and
        custom tokens, you can manage all of your digital assets in one place,
        with a clear overview of your portfolio and its value. Whether you're at
        home or in the office, our web panel provides the flexibility and
        convenience you need to manage your digital assets with ease.
      </div>
      <img src={WEB_PANEL_VECTOR} alt="icon" className="webpanel-img" />
    </main>
  );
}

export default WebPanel;
