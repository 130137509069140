import { useEffect } from "react";
import {
  Footer,
  ISWebPanel,
  ISTechnologies,
  ISNewFeatures,
  ISFreeDownload,
  ISCryptoWalletApp,
  ISCrateWallet,
  ISAndroidIOS,
  ISAboutWallet,
  ISAboutFeatures,
  Feedback,
  Layout,
} from "../components";

function InstaSoft() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Layout
        title="Instagram Clone for Android and IOS - Ready-made Script | Skyclones"
        metaTitle="Instagram Clone for Android and IOS - Ready-made Script | Skyclones"
        metaDesc=" Launch your own social media platform with our Instagram clone for Android and IOS. Our ready-made script allows you to easily search friends, explore public photos, and more!"
        metaKey=" Instagram clone, Android, IOS, social media platform, ready-made script, search friends, public photos, activities, Skyclones"
      />
      <ISCrateWallet />
      <ISAboutWallet />
      <ISAboutFeatures />
      <ISCryptoWalletApp />
      <ISWebPanel />
      <ISFreeDownload />
      <ISTechnologies />
      <ISAndroidIOS />
      <ISNewFeatures />
      <Feedback />
      <Footer />
    </>
  );
}

export default InstaSoft;
