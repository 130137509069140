import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * use this function scroll page bottom to top
 */
function ScrollTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [pathname]);

  return null;
}

export default ScrollTop