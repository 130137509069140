import React from "react";
import {
  DB_WA_VECTOR,
  DB_PHONE1,
  DB_PHONE2,
  DB_PHONE3,
  DB_PHONE4,
  DB_PHONE5,
} from "../../assets";
import { Slider } from "../Slider";

function DBCryptoWalletApp() {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const slideNext = (e) => {
    setCurrentSlide((prev) => {
      return prev + 1 === slides.length ? 0 : currentSlide + 1;
    });
  };
  const slidePrev = (e) => {
    setCurrentSlide((prev) => {
      return prev === 0 ? slides.length - 1 : currentSlide - 1;
    });
  };
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prev) => {
        return prev + 1 === slides.length ? 0 : prev + 1;
      });
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <main className="cwa-container">
      <div className="cwa-title">
        Doctor on demand Appointment booking system Readymade.
      </div>
      <div className="cwa-subtitle">
        Our script comes packed with a wide range of features, including a
        user-friendly dashboard, online appointment scheduling, patient
        management, prescription management, medical record management,
        automated notifications and reminders, and much more. With these
        features, healthcare providers can streamline their workflow and provide
        their patients with the best possible care.
      </div>
      <img src={DB_WA_VECTOR} alt="icon" className="img_sh" />
      <div className="Slider_sh">
        <Slider
          image={slides[currentSlide]}
          slideNext={slideNext}
          slidePrev={slidePrev}
        />
      </div>
    </main>
  );
}
export default DBCryptoWalletApp;

const slides = [
  {
    link: DB_PHONE1,
  },
  {
    link: DB_PHONE2,
  },
  {
    link: DB_PHONE3,
  },
  {
    link: DB_PHONE4,
  },
  {
    link: DB_PHONE5,
  },
];
