import { Link } from "react-router-dom";
import { IS_MAIN_VECTOR } from "../../assets";

function ISCrateWallet() {
  return (
    <main className="IS_main-container">
      <section className="webapp-flex">
        <div className="webapp-flex-col">
          <span className="webapp-title">INSTAGRAM Readymade Script</span>
          <span className="webapp-sub-title">
            Our Instagram clone is a readymade script for Android and IOS that
            allows startups to launch their own social media platform similar to
            Instagram. With our Instagram clone, you can provide users with a
            platform to share their photos and videos, connect with friends and
            followers, and grow their social media presence.
          </span>
          <div className="btn-flex">
            <Link to={process.env.REACT_APP_BLOG_INSTA_URL} target="_blank">
              <button className="red-btn-small">
                <div className="btn_align">Buy Now</div>
              </button>
            </Link>
            <Link to={process.env.REACT_APP_PLAY_STORE_INSTA_URL} target="_blank">
              <button className="white-btn-small">
                <div className="btn_align">Free Download</div>
              </button>
            </Link>
          </div>
        </div>
        <div>
          <img src={IS_MAIN_VECTOR} alt="vector" className="cryptowallet-img" />
        </div>
      </section>
    </main>
  );
}

export default ISCrateWallet;
