import React from 'react'

function WebPanels({ webPanel }) {
  return (
    <main className="WebPanel-container">
      <div className="WebPanel-title">{webPanel?.title}</div>
      <div className="cwa-subtitle">{webPanel?.subtitle}</div>
      <img src={webPanel?.panelImg} alt="icon" className="webpanel-img" />
    </main>
  )
}

export default WebPanels