import React from "react";

function Button({
  Img,
  Title,
  black,
  red,
  OnClickBtn,
  white,
  blue,
  isLoading,
  sty,
}) {
  const btnClass = black
    ? "black-btn-small"
    : red
    ? "red-btn-small"
    : white
    ? "white-btn-small"
    : blue
    ? "blue-btn-small "
    : "black-btn-small";
  //   const btnloader =
  //     black || red
  //       ? "white-btn-loader"
  //       : white || blue
  //       ? "blue-btn-loader"
  //       : "blue-btn-loader";
  return (
    <button
      onClick={OnClickBtn}
      className={`${btnClass} btn-small`}
      disabled={isLoading}
      style={sty}
    >
      <div className="btn_align">
        {Img && <img src={Img} alt="icon" className="btn_img" />}
        {/* isLoading ? <div className={btnloader} /> : */ Title}
      </div>
    </button>
  );
}

export default Button;
