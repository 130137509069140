import {
  TIK_YOUR_FRIEND_ICON,
  TIK_PROFILE_PHOTOS_ICON,
  TIK_NOTIFICATION_ICON,
  TIK_FOLLOW_FRIEND_ICON,
  TIK_PRICACY_ICON,
  TIK_LIKE_ICON,
} from "../../assets";

function TIKAboutFeatures() {
  return (
    <main className="TIKAboutFeatures-container">
      <div className="AboutFeatures-title">About Features</div>
      <div>
        <div className="AboutFeatures-wrapper">
          {AboutFeaturesData.map((item, index) => {
            return (
              <div className="AboutFeatures-box" key={index}>
                <div className="AboutFeatures-card">
                  <div className="AboutFeatures-img-flex">
                    <div className="TIKAboutFeatures-img-box">
                      <img
                        src={item?.IMG}
                        alt="icon"
                        className="TIKAboutFeatures-img_"
                      />
                    </div>
                    <div className="AboutFeatures-subtitle ">{item?.Title}</div>
                  </div>
                  <div className="AboutFeatures-info">{item?.describe}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
}
export default TIKAboutFeatures;

const AboutFeaturesData = [
  {
    IMG: TIK_YOUR_FRIEND_ICON,
    Title: "Search your friends",
    describe:
      "Our TikTok clone offers a search functionality to easily find and connect with friends and content creators. Users can search by username, real name, location, or other criteria. Our advanced algorithms provide accurate and personalized results. Grow your network within the TikTok community with our powerful search feature.",
  },
  {
    IMG: TIK_PROFILE_PHOTOS_ICON,
    Title: "Explore public profile photos",
    describe:
      "Our TikTok clone lets users explore public profile photos of other users, making it easy to discover new content creators and explore the profiles of other users. With our intuitive interface and seamless navigation, users can quickly and easily find what they're looking for. This feature helps users to grow their network within the TikTok community and discover new and engaging content. Explore the world of TikTok with our powerful and user-friendly app.",
  },
  {
    IMG: TIK_FOLLOW_FRIEND_ICON,
    Title: "follow your friends",
    describe:
      "Skyclones' TikTok clone allows users to easily follow their friends and other users within the app. By following your favorite content creators, you can discover new videos and stay up-to-date with the latest trends. Our user-friendly interface makes it easy to find and follow your friends, helping you build your own community within the app. Try our TikTok clone today and start exploring!",
  },
  {
    IMG: TIK_LIKE_ICON,
    Title: "Comment, like and bookmark",
    describe:
      "Skyclones' TikTok clone provides users with the ability to interact with videos in multiple ways. Users can leave comments, like videos, and bookmark their favorites for later viewing. This enhances user engagement and encourages them to return to the app frequently. Our intuitive interface makes it easy to perform these actions, ensuring a seamless experience for all users. Get our TikTok clone now and start creating, liking, and bookmarking videos with ease!",
  },
  {
    IMG: TIK_PRICACY_ICON,
    Title: "Change your pricacy",
    describe:
      "Skyclones' TikTok clone offers a dynamic video feed, where users can discover new content based on their interests. Our algorithm ensures that users see relevant and engaging videos in their feed, improving their overall experience. With the ability to like, comment, and share videos, our TikTok clone offers a platform for users to connect and engage with each other. Get our TikTok clone now and experience the excitement of a constantly updating video feed!",
  },
  {
    IMG: TIK_NOTIFICATION_ICON,
    Title: "Notification for activity",
    describe:
      "Our TikTok clone provides users with real-time notifications and activities, keeping them up to date with the latest happenings on the platform. From new followers and likes to comments and mentions, users receive instant notifications that help them stay connected with their followers and engagement. Our TikTok clone's comprehensive activity section allows users to track all their interactions on the platform, making it easy for them to manage their content and engagement. Get our TikTok clone now and never miss a beat on the platform!",
  },
];
