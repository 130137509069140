import { Link } from "react-router-dom";
import { ANDROID_ICON, APPLE_ICON, EC_ABOUT_VECTOR } from "../../assets";

function RBAboutWallet() {
  return (
    <main className="ECaboutwallet-container">
      <section className="cryptowallet-flex">
        <div>
          <img src={EC_ABOUT_VECTOR} alt="vector" className="aboutwallet-img" />
        </div>
        <div className="cryptowallet-flex-col">
          <span className="ECwallet-smtitle">Free Food Recipe Book Script</span>
          <span className="cryptowallet-title">
            Food Recipe Book Ready to Use Script
          </span>
          <span className="cryptowallet-sub-title">
            Welcome to our Food Recipe Book Readymade Script, a one-stop
            solution for all food enthusiasts out there. This mobile application
            is designed to provide users with a comprehensive collection of
            recipes for all kinds of food items. From appetizers to desserts,
            you can find a wide range of recipes to satisfy your taste buds.
          </span>
          <div className="cryptowallet-btn-text">
            <span className="cryptowallet-btn-text_">Checkout Live on</span>
            <div>
              <Link to={process.env.REACT_APP_IOS_FOOD_RECIPE_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={APPLE_ICON} alt="icon" className="btn_img" />
                    <span>iOS</span>
                  </div>
                </button>
              </Link>
              <Link to={process.env.REACT_APP_PLAY_STORE_FOOD_RECIPE_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={ANDROID_ICON} alt="icon" className="btn_img" />
                    <span>Android</span>
                  </div>
                </button>
              </Link>
            </div>
          </div>
          <div className="btn-flex">
            <Link to={process.env.REACT_APP_PLAY_STORE_FOOD_RECIPE_URL} target="_blank">
              <button className="black-btn-small">Free Download</button>
            </Link>
            <Link
              to={process.env.REACT_APP_BLOG_FOOD_RECIPE_URL}
              target="_blank"
            >
              <button className="black-btn-small">Buy Now</button>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default RBAboutWallet;
