import { DEDICATED_VECTOR } from "../../assets";

function DBNewFeatures() {
  return (
    <main className="Dedicated-container">
      <section className="Dedicated-flex">
        <div>
          <img src={DEDICATED_VECTOR} alt="vector" className="AndroidIOS-img" />
        </div>
        <div className="Dedicated-flex-col">
          <span className="Dedicated-smtitle">
            Appointment booking system Ready to use.
          </span>
          <span className="cryptowallet-title">Get a Free Quote</span>
          <span className="New-features-list">
            Get a free quote for Doctor On Demand Readymade Script to build your
            own customized Doctor Appointment Booking System that fits your
            unique requirements. Our team of experienced developers can help you
            in customizing the solution with advanced features and
            functionalities as per your business needs. Contact us now to know
            more.
          </span>
        </div>
      </section>
    </main>
  );
}

export default DBNewFeatures;
