import { Link } from "react-router-dom";
import { WEB_APP_VECTOR } from "../../assets";

function WebApp() {
  return (
    <main className="webapp-container">
      <section className="webapp-flex">
        <div className="webapp-flex-col">
          <span className="webapp-title">
            On-Demand Mobile Applications and Ready-to-Use Script.
          </span>
          <span className="webapp-sub-title">
            Skyclones offers on-demand mobile app development for Android and
            iOS, as well as ready-to-use scripts to build apps inspired by
            popular platforms. Our solutions are designed to help entrepreneurs
            and businesses launch their own applications quickly and easily.
          </span>
          <div className="btn-flex">
            <Link to={process.env.REACT_APP_BLOG_PRODUCT_URL} target="_blank">
              <button className="red-btn-small">
                <div className="btn_align">Buy Now</div>
              </button>
            </Link>
            <Link to={process.env.REACT_APP_PLAY_STORE_URL} target="_blank">
              <button className="white-btn-small">
                <div className="btn_align">Free Download</div>
              </button>
            </Link>
          </div>
        </div>
        <div className="webapp_vector">
          <img src={WEB_APP_VECTOR} alt="vector" className="cryptowallet-img" />
        </div>
      </section>
    </main>
  );
}

export default WebApp;
