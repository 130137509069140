import { Link } from "react-router-dom";
import {
  ANDROID_ICON,
  APPLE_ICON,
  IS_ABOUT_WALLET,
  WEB_ICON,
} from "../../assets";

function ISAboutWallet() {
  return (
    <main className="ISaboutwallet-container">
      <section className="cryptowallet-flex">
        <div>
          <img src={IS_ABOUT_WALLET} alt="vector" className="aboutwallet-img" />
        </div>
        <div className="cryptowallet-flex-col">
          <span className="ISwallet-smtitle">
            Free Instagram App Source Code
          </span>
          <span className="cryptowallet-title">Instagram Clone Script</span>
          <span className="cryptowallet-sub-title">
            Welcome to our Instagram readymade script! Our solution is designed
            to help startups launch their own social media platform, similar to
            Instagram. With our Instagram clone, you can provide users with all
            the essential features to grow their social media presence,
            including photo and video sharing, filters, stories, direct
            messaging, and more. Our Instagram clone is built for both Android
            and IOS platforms, ensuring maximum reach for your user base. The
            app is easy to use, with an intuitive user interface and smooth
            performance that provides a seamless experience to your users.
          </span>
          <div className="cryptowallet-btn-text">
            <span className="cryptowallet-btn-text_">Checkout Live on</span>
            <div>
              <Link to={process.env.REACT_APP_IOS_INSTA_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={APPLE_ICON} alt="icon" className="btn_img" />
                    <span>iOS</span>
                  </div>
                </button>
              </Link>
              <Link
                to={process.env.REACT_APP_PLAY_STORE_INSTA_URL}
                target="_blank"
              >
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={ANDROID_ICON} alt="icon" className="btn_img" />
                    <span>Android</span>
                  </div>
                </button>
              </Link>
              <Link to={process.env.REACT_APP_WEB_INSTA_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={WEB_ICON} alt="icon" className="btn_img" />
                    <span>Web</span>
                  </div>
                </button>
              </Link>
            </div>
          </div>
          <div className="btn-flex">
            <Link
              to={process.env.REACT_APP_PLAY_STORE_INSTA_URL}
              target="_blank"
            >
              <button className="black-btn-small">Free Download</button>
            </Link>
            <Link to={process.env.REACT_APP_BLOG_INSTA_URL} target="_blank">
              <button className="black-btn-small">Buy Now</button>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ISAboutWallet;
