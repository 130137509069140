import { DEDICATED_VECTOR } from "../../assets";

function RBNewFeatures() {
  return (
    <main className="Dedicated-container">
      <section className="Dedicated-flex">
        <div>
          <img src={DEDICATED_VECTOR} alt="vector" className="AndroidIOS-img" />
        </div>
        <div className="Dedicated-flex-col">
          <span className="Dedicated-smtitle">
            Food Recipe Book Readymade App
          </span>
          <span className="cryptowallet-title">Get a Free Quote</span>
          <span className="New-features-list">
            A free quote can be obtained for the Food Recipe Book Ready-made
            Script for Android and iOS. Fill out the provided form with your
            requirements and contact details, and a representative will get in
            touch with you to discuss your project and provide a quote.
          </span>
        </div>
      </section>
    </main>
  );
}

export default RBNewFeatures;
