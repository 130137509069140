import { Link } from "react-router-dom";
import {
  DMCA_IMG,
  EMAIL_ICON,
  FB_ICON,
  INSTA_ICON,
  LINKIN_ICON,
  LOCATION_ICON,
  LOGO,
  PHONE_ICON,
  TWITTER_ICON,
  YOUTUBE_ICON,
} from "../../assets";

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="footer-logo">
          <img src={LOGO} alt="logo" />
          <div className="footer-logo-line"></div>
          <div className="footer-logo-dot"></div>
        </div>
        <div className="footer-list">
          <div className="footer-list-flex">
            <div className="footer-list-title">Navigate</div>
            <div className="footer-list-line"></div>
            <Link to="/" className="footer-list-subtitle ">
              Home
            </Link>
            <Link
              to={process.env.REACT_APP_ABOUT_US_URL}
              target="_blank"
              className="footer-list-subtitle "
            >
              About Us
            </Link>
            <Link
              to={process.env.REACT_APP_BLOG_URL}
              target="_blank"
              className="footer-list-subtitle "
            >
              Blog
            </Link>
            <Link
              to={process.env.REACT_APP_BLOG_CONTACT_URL}
              target="_blank"
              className="footer-list-subtitle "
            >
              Contact Us
            </Link>
          </div>
          <div className="footer-list-flex">
            <div className="footer-list-title">Products</div>
            <div className="footer-list-line"></div>
            <div className="footer-list-line-flex">
              <div className="footer-list-line-flex-col">
                <Link to="/crypto-wallet" className="footer-list-subtitle ">
                  Crypto Wallet ERC20
                </Link>
                {/* <Link to="/BNBWallet" className="footer-list-subtitle ">
                  BnB Wallet BNB20
                </Link> */}
                <Link to="/food-recipe-book" className="footer-list-subtitle ">
                  My Recipe Book
                </Link>
                <Link to="/doctor-on-demand" className="footer-list-subtitle ">
                  Doctor booking system
                </Link>
                <Link to="/car-rental-script-download" className="footer-list-subtitle ">
                  Car rental 
                </Link>
              </div>
              <div className="footer-list-line-flex-col_">
                {/* <Link to="/ECommerce" className="footer-list-subtitle ">
                  E-Commerce
                </Link> */}
                <Link
                  to={process.env.REACT_APP_SKY_CONTROLLES_URL}
                  target="_blank"
                  className="footer-list-subtitle"
                >
                  SkyControlles
                </Link>
                <Link to="/tiktok-clone" className="footer-list-subtitle ">
                  TikTik
                </Link>
                <Link to="/instagram-clone" className="footer-list-subtitle ">
                  Instasoft
                </Link>
              </div>
            </div>
          </div>
          <div className="footer-list-flex">
            <div className="footer-list-title">Skyclones</div>
            <div className="footer-list-line"></div>
            <Link
              to={process.env.REACT_APP_TERMS_SERVICES_URL}
              target="_blank"
              className="footer-list-subtitle "
            >
              Terms of Services
            </Link>
            <Link
              to={process.env.REACT_APP_PRIVACY_POLICY_URL}
              target="_blank"
              className="footer-list-subtitle "
            >
              Privacy Policy
            </Link>
            <Link
              to={process.env.REACT_APP_REFUND_POLICY_URL}
              target="_blank"
              className="footer-list-subtitle "
            >
              Refund Policy
            </Link>
            <Link
              to={process.env.REACT_APP_DISCLAIMER_URL}
              target="_blank"
              className="footer-list-subtitle "
            >
              Disclaimer
            </Link>
            <Link
              to="/sitemap"
              target="_blank"
              className="footer-list-subtitle "
            >
              Sitemap
            </Link>
          </div>
          <div className="footer-list-flex">
            <div className="footer-list-title">Get In Touch</div>
            <div className="footer-list-line"></div>
            <Link
              to={process.env.REACT_APP_GOOGLE_MAP_URL}
              className="footer-list-subtitle footer-list-line-flex_ "
              target="_blank"
            >
              <img
                src={LOCATION_ICON}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
              Gondal Rd, Rajkot, Gujarat 360002
            </Link>
            <Link
              to={process.env.REACT_APP_GOOGLE_MAIL_URL}
              className="footer-list-subtitle footer-list-line-flex_ "
              target="_blank"
            >
              <img
                src={EMAIL_ICON}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
              info@skyclones.com
            </Link>
            <Link
              to={process.env.REACT_APP_WHATSAPP_URL}
              className="footer-list-subtitle footer-list-line-flex_ "
              target="_blank"
            >
              <img
                src={PHONE_ICON}
                alt="icon"
                style={{ marginRight: "10px" }}
              />
              +91 9327475307 - Whatsapp Now
            </Link>
          </div>
        </div>
        <div className="footer-social-icon">
          <Link
            to={process.env.REACT_APP_TWITTER_URL}
            className="footer-social-box"
            target="_blank"
          >
            <img src={TWITTER_ICON} alt="social" />
          </Link>
          <Link
            to={process.env.REACT_APP_INSTA_URL}
            className="footer-social-box"
            target="_blank"
          >
            <img src={INSTA_ICON} alt="social" />
          </Link>
          <Link
            to={process.env.REACT_APP_FACEBOOK_URL}
            className="footer-social-box"
            target="_blank"
          >
            <img src={FB_ICON} alt="social" />
          </Link>
          <Link
            to={process.env.REACT_APP_LINKEDIN_URL}
            className="footer-social-box"
            target="_blank"
          >
            <img src={LINKIN_ICON} alt="social" />
          </Link>
          <Link
            to={process.env.REACT_APP_YOUTUBE_URL}
            className="footer-social-box"
            target="_blank"
          >
            <img src={YOUTUBE_ICON} alt="social" />
          </Link>
        </div>
      </footer>
      <div className="copyright">
        <span className="copyright_text">
          Disclaimer: Every organization trademark mentioned on the website does
          not belong to us or has no control over it. and we have no desire for
          wrongdoing to any organization or individuals.{" "}
          {new Date().getDate() +
            "/" +
            new Date().getMonth() +
            "/" +
            new Date().getFullYear()}
        </span>
        <img src={DMCA_IMG} alt="icon" />
      </div>
    </>
  );
}

export default Footer;
