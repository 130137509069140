function RBFreeDownload() {
  return (
    <main className="EC_Free-container">
      <div className="cwa-title">Food Recipe Book Readymade Script Video</div>
      <div className="cwa-subtitle">
        Our Food Recipe Book Readymade Script is available for both Android and
        iOS platforms, making it accessible to a wide range of users. With its
        comprehensive features and user-friendly interface, this app is perfect
        for anyone who loves to cook and try out new recipes. Get started today
        and explore the world of delicious food recipes!
      </div>
      <iframe
        title="BNB"
        width="0px"
        height="600px"
        src={process.env.REACT_APP_YOUTUBE_FOOD_RECIPE_URL}
      ></iframe>
    </main>
  );
}

export default RBFreeDownload;
