import { useEffect } from "react";
import {
  BNBWallet,
  CryptoWallet,
  AppointmentBooking,
  DedicatedSupport,
  FAQs,
  Feedback,
  Footer,
  Instasoft,
  SwiftuiControls,
  Tiktik,
  WebApp,
  ResipeBook,
  Layout,
  CarRental,
} from "../components";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Layout
        title="On-Demand Mobile App Development and Ready-to-Use Scripts"
        metaTitle="On-Demand Mobile App Development and Ready-to-Use Scripts"
        metaDesc="Skyclones offers on-demand mobile app development for Android and iOS, as well as ready-to-use scripts to create apps like TikTok, Instagram, and more. Contact us today to learn more!"
        metaKey="on-demand mobile app development, ready-to-use scripts, Skyclones, Tiktok Script, Intagram Script, Readymade Script, Doctor on demand script, Shopify to Mobile App convertor"
      />
      <WebApp />
      <CryptoWallet />
      <BNBWallet />
      <AppointmentBooking />
      <SwiftuiControls />
      <Tiktik />
      <Instasoft />
      <ResipeBook />
      <CarRental/>
      <FAQs />
      <DedicatedSupport />
      <Feedback />
      <Footer />
      {/* <ShopifyStorefront /> */}
    </>
  );
}
export default Home;
