import React from 'react'

function AboutFeature({ aboutFeatures }) {
    return (
        <main className="BNBAboutFeatures-container" style={{ backgroundImage: `url(${aboutFeatures?.backgroundImage})` }}>
            <div className="AboutFeatures-title">{aboutFeatures?.title}</div>
            <div>
                <div className="AboutFeatures-wrapper">
                    {aboutFeatures?.Features.map((item, index) => {
                        return (
                            <div className="AboutFeatures-box" key={index}>
                                <div className="AboutFeatures-card">
                                    <div className="AboutFeatures-img-flex">
                                        <div className="BNBAboutFeatures-img-box">
                                            <img
                                                src={item?.IMG}
                                                alt="icon"
                                                className="BNBAboutFeatures-img_"
                                            />
                                        </div>
                                        <div className="AboutFeatures-subtitle ">{item?.Title}</div>
                                    </div>
                                    <div className="AboutFeatures-info">{item?.describe}</div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </main>
    )
}

export default AboutFeature
