import { DEDICATED_VECTOR } from "../../assets";

function ISNewFeatures() {
  return (
    <main className="Dedicated-container">
      <section className="Dedicated-flex">
        <div>
          <img src={DEDICATED_VECTOR} alt="vector" className="AndroidIOS-img" />
        </div>
        <div className="Dedicated-flex-col">
          <span className="Dedicated-smtitle">
            Instagram Readymade Source Code
          </span>
          <span className="cryptowallet-title">Get A Free Quote</span>
          <span className="New-features-list">
            Looking to start your own social media app like Instagram? Get a
            free quote today for our Instagram clone script and kickstart your
            business in no time. Our team of experts will assist you with all
            the technical details and ensure a seamless setup process for your
            platform. Contact us now for a personalized quote.
          </span>
        </div>
      </section>
    </main>
  );
}

export default ISNewFeatures;
