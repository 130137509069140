import {
  CWA_VECTOR,
  CRYPTO_PHONE1,
  CRYPTO_PHONE2,
  CRYPTO_PHONE3,
  CRYPTO_PHONE4,
  CRYPTO_PHONE5,
} from "../../assets";
import React from "react";
import { Slider } from "../Slider";

function CryptoWalletApp() {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const slideNext = (e) => {
    setCurrentSlide((prev) => {
      return prev + 1 === slides.length ? 0 : currentSlide + 1;
    });
  };
  const slidePrev = (e) => {
    setCurrentSlide((prev) => {
      return prev === 0 ? slides.length - 1 : currentSlide - 1;
    });
  };
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prev) => {
        return prev + 1 === slides.length ? 0 : prev + 1;
      });
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <main className="cwa-container">
      <div className="cwa-title">Free Download Crypto Wallet App</div>
      <div className="cwa-subtitle">
        With Skyclones' Crypto Wallet, you can manage your digital assets on the
        go, with our mobile app for Android and iOS devices. Our app provides a
        simple and intuitive interface for managing your cryptocurrency, making
        it easy to send and receive payments, check your balances, and track
        your transactions from anywhere in the world. Plus, with features like
        fingerprint authentication and two-factor authentication, you can have
        confidence that your funds are secure, even when you're on the go.
        Whether you're at home or on the move, our mobile app provides the
        flexibility and convenience you need to manage your digital assets with
        ease.
      </div>
      <img src={CWA_VECTOR} alt="icon" className="img_sh" />
      <div className="Slider_sh">
        <Slider
          image={slides[currentSlide]}
          slideNext={slideNext}
          slidePrev={slidePrev}
        />
      </div>
    </main>
  );
}
export default CryptoWalletApp;

const slides = [
  {
    link: CRYPTO_PHONE1,
  },
  {
    link: CRYPTO_PHONE2,
  },
  {
    link: CRYPTO_PHONE3,
  },
  {
    link: CRYPTO_PHONE4,
  },
  {
    link: CRYPTO_PHONE5,
  },
];
