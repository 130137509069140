import { Link } from "react-router-dom";
import {
  ANDROID_ICON,
  APPLE_ICON,
  INSTASOFT_VECTOR,
  WEB_ICON,
} from "../../assets";

function Instasoft() {
  return (
    <main className="Instasoft-container Instasoft_center_BG">
      <section className="Instasoft-flex">
        <div className="Instasoft-flex-col">
          <span className="Instasoft-smtitle">
            Free Instagram Readymade Script
          </span>
          <Link to="/instagram-clone" className="cryptowallet-title">
            Instagram Readymade Script
          </Link>
          <span className="cryptowallet-sub-title">
            Skyclones' Instagram Readymade Script is a complete solution for
            building an Instagram-like app for Android and iOS. Our script comes
            with a range of features, including photo and video sharing, social
            media integration, and more. With our script, you can create your
            own social media platform quickly and easily. Create your own social
            media platform today with Skyclones' Instagram Readymade Script and
            start building the next big thing in social media.
          </span>
          <div className="cryptowallet-btn-text">
            <span className="cryptowallet-btn-text_">Checkout Live on</span>
            <div>
              <Link to={process.env.REACT_APP_IOS_INSTA_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={APPLE_ICON} alt="icon" className="btn_img" />
                    <span>iOS</span>
                  </div>
                </button>
              </Link>
              <Link
                to={process.env.REACT_APP_PLAY_STORE_INSTA_URL}
                target="_blank"
              >
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={ANDROID_ICON} alt="icon" className="btn_img" />
                    <span>Android</span>
                  </div>
                </button>
              </Link>
              <Link to={process.env.REACT_APP_WEB_INSTA_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={WEB_ICON} alt="icon" className="btn_img" />
                    <span>Web</span>
                  </div>
                </button>
              </Link>
            </div>
          </div>
          <div className="btn-flex">
            <Link
              to={process.env.REACT_APP_PLAY_STORE_INSTA_URL}
              target="_blank"
            >
              <button className="black-btn-small">Free Download</button>
            </Link>
            <Link to={process.env.REACT_APP_BLOG_INSTA_URL} target="_blank">
              <button className="black-btn-small">Buy Now</button>
            </Link>
          </div>
        </div>
        <div className="Instasoft_img">
          <img
            src={INSTASOFT_VECTOR}
            alt="vector"
            className="cryptowallet-img"
          />
        </div>
      </section>
    </main>
  );
}

export default Instasoft;
