import { Link } from "react-router-dom";
import { ANDROID_ICON, APPLE_ICON, BNB_WALLET_VECTOR } from "../../assets";

function BNBWallet() {
  return (
    <main className="BNBWallet-container BNB_about_right_BG ">
      <section className="BNBWallet-flex">
        <div className="BNBWallet-flex-col">
          <span className="BNBWallet-smtitle">Free BNB Wallet Script</span>
          <Link to="/crypto-wallet" className="cryptowallet-title">
            BNB Wallet
          </Link>
          <span className="cryptowallet-sub-title">
            Skyclones' BNB crypto wallet is a secure and reliable solution for
            managing your Binance Coin. Available for Android, iOS, and web, our
            wallet is easy to use and free to download. Download our BNB crypto
            wallet for free today and start managing your Binance Coin with ease
            and peace of mind.
          </span>
          <div className="cryptowallet-btn-text">
            <span className="cryptowallet-btn-text_">Checkout Live on</span>
            <div>
              <Link to={process.env.REACT_APP_IOS_BNB_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={APPLE_ICON} alt="icon" className="btn_img" />
                    <span>iOS</span>
                  </div>
                </button>
              </Link>
              <Link to={process.env.REACT_APP_PLAY_STORE_BNB_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={ANDROID_ICON} alt="icon" className="btn_img" />
                    <span>Android</span>
                  </div>
                </button>
              </Link>
            </div>
          </div>
          <div className="btn-flex">
            <Link to={process.env.REACT_APP_PLAY_STORE_BNB_URL} target="_blank">
              <button className="black-btn-small">Free Download</button>
            </Link>
            <Link to={process.env.REACT_APP_BLOG_PRODUCT_CRYPTO_URL} target="_blank">
              <button className="black-btn-small">Buy Now</button>
            </Link>
          </div>
        </div>
        <div className="BNBWallet_img">
          <img
            src={BNB_WALLET_VECTOR}
            alt="vector"
            className="cryptowallet-img"
          />
        </div>
      </section>
    </main>
  );
}

export default BNBWallet;
