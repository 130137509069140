import React from "react";
import { Link } from "react-router-dom";

function MainPage({ mainPage }) {
  return (<>
    <main className="BNB_main-container" style={{ backgroundImage: `url(${mainPage?.backgroundImage})` }}>
      <section className="webapp-flex">
        <div className="webapp-flex-col">
          <span className="webapp-title">
            {mainPage?.title}
          </span>
          <span className="webapp-sub-title">
            {mainPage?.subtitle}
          </span>
          {/* <div className="btn-flex">
            <Link to={mainPage?.to} target="_blank">
              <button className="red-btn-small">
                <div className="btn_align">{mainPage?.btnName}</div>
              </button>
            </Link>
          </div> */}
          <div className="btn-flex">
            <Link
              to={mainPage?.buyNow}
              target="_blank"
            >
              <button className="red-btn-small">
                <div className="btn_align">Buy Now</div>
              </button>
            </Link>
            {mainPage?.freeDown &&
              <Link to={mainPage?.freeDown} target="_blank">
                <button className="white-btn-small">
                  <div className="btn_align">Free Download</div>
                </button>
              </Link>}
          </div>
        </div>
        <div>
          <img
            src={mainPage?.vector}
            alt="vector"
            className="cryptowallet-img"
          />
        </div>
      </section>
    </main>
  </>
  );
}

export default MainPage;
