import React, { Fragment } from "react";
import { AboutFeature, AboutUS, AppOverview, BusinessInfo, DownloadSection, Footer, Layout, MainPage, Technologie, VideoPlay, WebPanels } from "../components";
import { BNB_ABOUT_WALLET, BNB_AI_VECTOR, BNB_MAIN_VECTOR, BNB_PHONE1, BNB_SECURE_WALLET_ICON, BNB_WA_VECTOR, DB_WEB_DASH, DEDICATED_VECTOR } from "../assets";

function DynamicPage() {
  return (
    <Fragment>
      {DynamicData.map((item, index) => {
        return (
          <div key={index}>
            <Layout
              title={item?.title}
              metaTitle={item?.metaTitle}
              metaDesc={item?.metaDesc}
              metaKey={item?.metaKey}
            />
            <MainPage mainPage={item?.MainPage} />
            <AboutUS aboutus={item?.Aboutus} />
            <AboutFeature aboutFeatures={item?.AboutFeatures} />
            <AppOverview appOverview={item?.AppOverview} />
            <WebPanels webPanel={item?.webPabel} />
            <VideoPlay videoPlay={item?.VideoPlay} />
            <Technologie technologie={item?.Technologie} />
            <DownloadSection downloadSection={item?.DownloadSection} />
            <BusinessInfo businessInfo={item?.BusinessInfo} />
            <Footer />
          </div>
        )
      })}
    </Fragment>
  );
}

export default DynamicPage;

const DynamicData = [
  {
    title: "Create Your Own Crypto Wallet with Skyclones.com | Crypto Wallet Readymade Script Download",
    metaTitle: "Create Your Own Crypto Wallet with Skyclones.com | Crypto Wallet Readymade Script Download",
    metaDesc: "Build your own secure and reliable crypto wallet with Skyclones.com's readymade script. Download our Crypto Wallet script and start managing your cryptocurrencies with ease.",
    metaKey: "crypto wallet, cryptocurrency, Skyclones, readymade script, download, manage cryptocurrencies, secure, reliable.",
    MainPage: {
      backgroundImage: "images/Crypto-BG.svg",
      title: "Lorem ipsum dolor sit, consectetur",
      subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur",
      btnName: "Let's Talk",
      to: "#",
      vector: BNB_MAIN_VECTOR,
    },
    Aboutus: {
      backgroundColor: "#f2f8ff",
      vector: BNB_ABOUT_WALLET,
      aboutTextcolor: "",
      title: "Lorem ipsum",
      subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      // buyLink: process.env.REACT_APP_BLOG_PRODUCT_CRYPTO_URL,
      // freeDownloadLink: process.env.REACT_APP_PLAY_STORE_BNB_URL,
      // iosAppLink: process.env.REACT_APP_IOS_BNB_URL,
      // androidAppLink: process.env.REACT_APP_PLAY_STORE_BNB_URL,
    },
    AboutFeatures: {
      backgroundImage: "images/Features-BG-BNB.svg",
      title: "About Features",
      Features: [
        {
          IMG: BNB_SECURE_WALLET_ICON,
          Title: "Secure Wallet",
          describe:
            "Create or import your secure wallet using  12 or 24 phrase words to secure your crypto currencies, you can export your wallet private key for future use for recover your wallet ",
        },
        {
          IMG: BNB_SECURE_WALLET_ICON,
          Title: "Balance and transactions",
          describe:
            "You can see your custom token as well as main token balance on the dashboard and each token transaction history",
        },
        {
          IMG: BNB_SECURE_WALLET_ICON,
          Title: "Multiple coins and balance ",
          describe:
            "You can hold multiple crypto currency on your wallet like Etherium or Binance based currency",
        },
        {
          IMG: BNB_SECURE_WALLET_ICON,
          Title: "Custom Crypto token",
          describe:
            "Wallet is supported for your own custom etherium and bianace based tokens, user can do all kind of operation on that ",
        },
        {
          IMG: BNB_SECURE_WALLET_ICON,
          Title: "Send/Recieve Crypto",
          describe:
            "You can send and receive crypto or custom token to your wallet address by QR code or manual process",
        },
        {
          IMG: BNB_SECURE_WALLET_ICON,
          Title: "block chains",
          describe:
            "You can test your tokens and crypto on the testing blockchain network as well",
        },
      ]
    },
    AppOverview: {
      vector: BNB_WA_VECTOR,
      vector1: [
        {
          link: BNB_PHONE1,
        },
        {
          link: BNB_PHONE1,
        },
        {
          link: BNB_PHONE1,
        },
        {
          link: BNB_PHONE1,
        },
        {
          link: BNB_PHONE1,
        },
      ]
    },
    webPabel: {
      title: "Web panel",
      subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolo",
      panelImg: DB_WEB_DASH,
    },
    VideoPlay: {
      backgroundImage: "images/BNB_youtube_Bg.svg",
      title: "Lorem ipsume",
      subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolo",
      videoLink: process.env.REACT_APP_YOUTUBE_BNB_URL,
    },
    Technologie: {
      title: "Technologies",
      subtitle: "Uber Clone is built using the latest technologies to ensure a seamless and efficient user experience. Our app is developed using React Native, which ensures a fast and responsive interface for both Android and iOS users.",
    },
    DownloadSection: {
      title: "Download App & Check Web Panel",
      subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
      GooglePlayLink: process.env.REACT_APP_PLAY_STORE_BNB_URL,
      AppStoreLink: process.env.REACT_APP_IOS_BNB_URL,
      vector: BNB_AI_VECTOR,
    },
    BusinessInfo: {
      vector: DEDICATED_VECTOR,
      title: "Get Free Quote",
      subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    }
  }
]