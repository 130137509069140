import { Link } from "react-router-dom";
import { ANDROID_ICON, APPLE_ICON, CRYPTO_WALLET_VECTOR } from "../../assets";

function CryptoWallet() {
  return (
    <main className="cryptowallet-container  Cry_about_left_BG">
      <section className="cryptowallet-flex">
        <div>
          <img
            src={CRYPTO_WALLET_VECTOR}
            alt="vector"
            className="cryptowallet-img"
          />
        </div>
        <div className="cryptowallet-flex-col">
          <span className="cryptowallet-smtitle">
            Free Crypto Wallet Script
          </span>
          <Link to="/crypto-wallet" className="cryptowallet-title">
            Crypto Wallet
          </Link>
          <span className="cryptowallet-sub-title">
            Skyclones' crypto wallet is a secure and user-friendly solution for
            managing your digital assets. Available for Android, iOS, and web,
            our wallet is easy to use and free to download. Download our crypto
            wallet for free today and start managing your digital assets with
            ease and peace of mind.
          </span>
          <div className="cryptowallet-btn-text">
            <span className="cryptowallet-btn-text_">Checkout Live on</span>
            <div>
              <Link to={process.env.REACT_APP_IOS_CRYPTO_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={APPLE_ICON} alt="icon" className="btn_img" />
                    <span>iOS</span>
                  </div>
                </button>
              </Link>
              <Link
                to={process.env.REACT_APP_PLAY_STORE_CRYPTO_URL}
                target="_blank"
              >
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={ANDROID_ICON} alt="icon" className="btn_img" />
                    <span>Android</span>
                  </div>
                </button>
              </Link>
            </div>
          </div>
          <div className="btn-flex">
            <Link
              to={process.env.REACT_APP_PLAY_STORE_CRYPTO_URL}
              target="_blank"
            >
              <button className="black-btn-small">Free Download</button>
            </Link>
            <Link
              to={process.env.REACT_APP_BLOG_PRODUCT_CRYPTO_URL}
              target="_blank"
            >
              <button className="black-btn-small">Buy Now</button>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default CryptoWallet;
