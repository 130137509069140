import {
  EC_CATEGORY_ICON,
  EC_FILTER_ICON,
  EC_PAYMENT_ICON,
  EC_PRODUCT_ICON,
  EC_ORDER_ICON,
  EC_CHECKOUT_ICON,
} from "../../assets";

function RBAboutFeatures() {
  return (
    <main className="ECAboutFeatures-container">
      <div className="AboutFeatures-title">Food Recipe Book App Features</div>
      <div>
        <div className="AboutFeatures-wrapper">
          {AboutFeaturesData.map((item, index) => {
            return (
              <div className="AboutFeatures-box" key={index}>
                <div className="AboutFeatures-card">
                  <div className="AboutFeatures-img-flex">
                    <div className="ECAboutFeatures-img-box">
                      <img
                        src={item?.IMG}
                        alt="icon"
                        className="ECAboutFeatures-img_"
                      />
                    </div>
                    <div className="AboutFeatures-subtitle ">{item?.Title}</div>
                  </div>
                  <div className="AboutFeatures-info">{item?.describe}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
}
export default RBAboutFeatures;

const AboutFeaturesData = [
  {
    IMG: EC_CATEGORY_ICON,
    Title: "Recipes",
    describe:
      "Our Food Recipe Book ready-made script for Android and iOS offers a comprehensive platform for food enthusiasts to discover, save, and share their favorite recipes. With this app, users can explore a wide range of recipes with ingredients and step-by-step cooking instructions.",
  },
  {
    IMG: EC_FILTER_ICON,
    Title: "add recipe",
    describe: `The "Add Recipe" feature of the Food Recipe Book Readymade Script allows users to create and publish their own recipes. Users can input the recipe's name, ingredients, cooking instructions, and even add photos. The feature enables users to share their unique recipes with others on the platform, expanding the recipe book and fostering a sense of community around cooking and food.`,
  },
  {
    IMG: EC_PRODUCT_ICON,
    Title: "Profile",
    describe:
      "The profile feature in the food recipe book ready-made script allows users to create their own profile with a profile picture, name, bio, and social media links. Users can also view other users' profiles and follow them for updates on new recipes they add. This feature helps to build a community of food enthusiasts and enables users to share their passion for cooking with others.",
  },
  {
    IMG: EC_CHECKOUT_ICON,
    Title: "favourites",
    describe:
      "The favorite feature of the food recipe book readymade script allows users to save their favorite recipes in one place. Users can easily add a recipe to their favorite list and access it later for cooking. This feature makes it easier for users to keep track of the recipes they love and want to try again in the future. Additionally, users can also remove recipes from their favorite list as per their preference.",
  },
  {
    IMG: EC_ORDER_ICON,
    Title: "admin panel",
    describe:
      "The admin panel for the Food Recipe Book script allows the site administrator to manage various aspects of the platform. This includes adding and removing recipe categories, managing user accounts, reviewing and publishing user-submitted recipes, and more. The admin panel is designed to be user-friendly and intuitive, even for those with limited technical expertise. By providing a centralized location for managing the platform, the admin panel helps to ensure that the Food Recipe Book runs smoothly and efficiently.",
  },
  {
    IMG: EC_PAYMENT_ICON,
    Title: "notification",
    describe:
      "The notification feature in our food recipe book readymade script keeps users informed about their activity on the app. Users receive notifications for new recipes, recipe updates, comments, likes, and followers. This ensures that users are always up to date with the latest recipes and activity on the app. Users can also customize their notification settings to manage the type of notifications they receive.",
  },
];
