import { Link } from "react-router-dom";
import { CRATEWALLET_VECTOR } from "../../assets";

function CrateWallet() {
  return (
    <main className="CrateWallet-container">
      <section className="webapp-flex">
        <div className="webapp-flex-col">
          <span className="webapp-title">Crypto Wallet Readymade Script</span>
          <span className="webapp-sub-title">
            Skyclones' Write Crypto Wallet is a readymade script for startups
            looking to launch their own crypto wallet for Android and iOS
            platforms. Our wallet script is easy to customize, secure, and
            packed with features like multi-currency support, transaction
            history, and more. And the best part? You can get started for just
            $99
          </span>
          <div className="btn-flex">
            <Link
              to={process.env.REACT_APP_BLOG_PRODUCT_CRYPTO_URL}
              target="_blank"
            >
              <button className="red-btn-small">
                <div className="btn_align">Buy Now</div>
              </button>
            </Link>
            <Link to={process.env.REACT_APP_PLAY_STORE_CRYPTO_URL} target="_blank">
              <button className="white-btn-small">
                <div className="btn_align">Free Download</div>
              </button>
            </Link>
          </div>
        </div>
        <div>
          <img
            src={CRATEWALLET_VECTOR}
            alt="vector"
            className="cryptowallet-img"
          />
        </div>
      </section>
    </main>
  );
}

export default CrateWallet;
