import React from "react";
import {
  IS_WA_VECTOR,
  IS_PHONE1,
  IS_PHONE2,
  IS_PHONE3,
  IS_PHONE4,
  IS_PHONE5,
} from "../../assets";
import { Slider } from "../Slider";

function ISCryptoWalletApp() {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const slideNext = (e) => {
    setCurrentSlide((prev) => {
      return prev + 1 === slides.length ? 0 : currentSlide + 1;
    });
  };
  const slidePrev = (e) => {
    setCurrentSlide((prev) => {
      return prev === 0 ? slides.length - 1 : currentSlide - 1;
    });
  };
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prev) => {
        return prev + 1 === slides.length ? 0 : prev + 1;
      });
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <main className="cwa-container">
      <div className="cwa-title">Readymade Instagram App Script</div>
      <div className="cwa-subtitle">
        With our Instagram clone, you get access to all the essential features
        of the original platform, including the ability to follow and unfollow
        users, like and comment on posts, share posts with others, explore new
        content, and more. Our clone also includes advanced features such as
        direct messaging, stories, and the ability to customize your profile to
        your liking.
      </div>
      <img src={IS_WA_VECTOR} alt="icon" className="img_sh" />
      <div className="Slider_sh">
        <Slider
          image={slides[currentSlide]}
          slideNext={slideNext}
          slidePrev={slidePrev}
        />
      </div>
    </main>
  );
}
export default ISCryptoWalletApp;

const slides = [
  {
    link: IS_PHONE1,
  },
  {
    link: IS_PHONE2,
  },
  {
    link: IS_PHONE3,
  },
  {
    link: IS_PHONE4,
  },
  {
    link: IS_PHONE5,
  },
];
