import {
  T_ANDROID_ICON,
  T_IOS_ICON,
  T_KOTLIN_ICON,
  T_REACT_ICON,
  T_WEB_ICON,
} from "../../assets";

function TIKTechnologies() {
  return (
    <main className="Technologies-container">
      <div className="WebPanel-title">Technologies</div>
      <div className="cwa-subtitle">
        Skyclones' TikTok clone is built using the latest technologies and
        frameworks to ensure a fast, responsive, and reliable user experience.
        Our app is developed with React Native, a popular framework for building
        cross-platform mobile apps. React Native allows us to build high-quality
        apps for both Android and iOS platforms while maintaining a single
        codebase.
      </div>
      <div>
        <div className="Technologies-wrapper">
          <div className="Technologies-box">
            <img src={T_ANDROID_ICON} alt="icon" />
            <span>Android</span>
          </div>
          <div className="Technologies-box">
            <img src={T_IOS_ICON} alt="icon" />
            <span>ios</span>
          </div>
          <div className="Technologies-box">
            <img src={T_KOTLIN_ICON} alt="icon" />
            <span>Kotlin</span>
          </div>
          <div className="Technologies-box">
            <img src={T_WEB_ICON} alt="icon" />
            <span>Web</span>
          </div>
          <div className="Technologies-box">
            <img src={T_REACT_ICON} alt="icon" />
            <span>React</span>
          </div>
        </div>
      </div>
    </main>
  );
}

export default TIKTechnologies;
