import React from "react";
import {
  EC_WA_VECTOR,
  EC_PHONE1,
  EC_PHONE2,
  EC_PHONE3,
  EC_PHONE4,
  EC_PHONE5,
} from "../../assets";
import { Slider } from "../Slider";

function RBCryptoWalletApp() {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const slideNext = (e) => {
    setCurrentSlide((prev) => {
      return prev + 1 === slides.length ? 0 : currentSlide + 1;
    });
  };
  const slidePrev = (e) => {
    setCurrentSlide((prev) => {
      return prev === 0 ? slides.length - 1 : currentSlide - 1;
    });
  };
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prev) => {
        return prev + 1 === slides.length ? 0 : prev + 1;
      });
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <main className="cwa-container">
      <div className="cwa-title">Food Recipe Book Android and IOS Script</div>
      <div className="cwa-subtitle">
        Our Food Recipe Book Readymade Script comes with a user-friendly
        interface that makes it easy for users to navigate through the app.
        Users can search for recipes based on different categories, such as
        cuisine, ingredients, and cooking time. They can also save their
        favorite recipes and even create their own recipe collections.
      </div>
      <img src={EC_WA_VECTOR} alt="icon" className="img_sh" />
      <div className="Slider_sh">
        <Slider
          image={slides[currentSlide]}
          slideNext={slideNext}
          slidePrev={slidePrev}
        />
      </div>
    </main>
  );
}
export default RBCryptoWalletApp;

const slides = [
  {
    link: EC_PHONE1,
  },
  {
    link: EC_PHONE2,
  },
  {
    link: EC_PHONE3,
  },
  {
    link: EC_PHONE4,
  },
  {
    link: EC_PHONE5,
  },
];
