import { useEffect } from "react";
import {
  Footer,
  DBWebPanel,
  DBTechnologies,
  DBNewFeatures,
  DBFreeDownload,
  DBCryptoWalletApp,
  DBCrateWallet,
  DBAndroidIOS,
  DBAboutWallet,
  DBAboutFeatures,
  Feedback,
  Layout,
} from "../components";

function DoctorBooking() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Layout
        title="Doctor On Demand Script - Ready to Use Appointment Booking System"
        metaTitle="Doctor On Demand Script - Ready to Use Appointment Booking System"
        metaDesc=" Doctor On Demand Script is a readymade solution for a video consultation, appointment booking system. Get a free quote and easily manage your appointments, prescriptions, and schedules with advanced features."
        metaKey=" Doctor On Demand Script, Appointment Booking System, Video Consultation, Free Quote, Manage Appointments, Prescriptions, Schedules, Advanced Features."
      />
      <DBCrateWallet />
      <DBAboutWallet />
      <DBAboutFeatures />
      <DBCryptoWalletApp />
      <DBWebPanel />
      <DBFreeDownload />
      <DBTechnologies />
      <DBAndroidIOS />
      <DBNewFeatures />
      <Feedback />
      <Footer />
    </>
  );
}

export default DoctorBooking;
