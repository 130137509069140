import { Link } from "react-router-dom";
import { EC_MAIN_VECTOR } from "../../assets";

function RBCrateWallet() {
  return (
    <main className="RB_main-container">
      <section className="webapp-flex">
        <div className="webapp-flex-col">
          <span className="webapp-title">
            Food Recipe Book Readymade Script
          </span>
          <span className="webapp-sub-title">
            Our Food Recipe Book Readymade Script is a mobile application for
            Android and iOS that provides a collection of delicious food
            recipes. The app allows users to search for recipes, add them to
            their favorites, and even create their own recipe collection. With a
            user-friendly interface and comprehensive features, this app is
            perfect for food lovers.
          </span>
          <div className="btn-flex">
            <Link
              to={process.env.REACT_APP_BLOG_FOOD_RECIPE_URL}
              target="_blank"
            >
              <button className="red-btn-small">
                <div className="btn_align">Buy Now</div>
              </button>
            </Link>
            <Link to={process.env.REACT_APP_PLAY_STORE_FOOD_RECIPE_URL} target="_blank">
              <button className="white-btn-small">
                <div className="btn_align">Free Download</div>
              </button>
            </Link>
          </div>
        </div>
        <div>
          <img src={EC_MAIN_VECTOR} alt="vector" className="cryptowallet-img" />
        </div>
      </section>
    </main>
  );
}

export default RBCrateWallet;
