import { Link } from "react-router-dom";
import { ABOUT_WALLET_VECTOR, ANDROID_ICON, APPLE_ICON } from "../../assets";

function AboutWallet() {
  return (
    <main className="aboutwallet-container">
      <section className="cryptowallet-flex">
        <div>
          <img
            src={ABOUT_WALLET_VECTOR}
            alt="vector"
            className="aboutwallet-img"
          />
        </div>
        <div className="cryptowallet-flex-col">
          <span className="cryptowallet-smtitle">
            Free Crypto Wallet App Script Download
          </span>
          <span className="cryptowallet-title">
            Crypto Wallet App Source Code
          </span>
          <span className="cryptowallet-sub-title">
            Are you looking to launch your own crypto wallet? Look no further
            than Skyclones' Write Crypto Wallet. Our readymade script for
            Android and iOS platforms is the perfect solution for startups
            looking to get their wallet off the ground quickly and easily.Our
            wallet script is packed with features like multi-currency support,
            transaction history, and secure authentication, making it a great
            choice for both novice and experienced cryptocurrency users. What's
            more, our script is easy to customize, so you can tailor it to your
            specific needs and branding. And with our affordable price of just
            $99, it's accessible to startups on a tight budget.
          </span>
          <div className="cryptowallet-btn-text">
            <span className="cryptowallet-btn-text_">Checkout Live on</span>
            <div>
              <Link to={process.env.REACT_APP_IOS_CRYPTO_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={APPLE_ICON} alt="icon" className="btn_img" />
                    <span>iOS</span>
                  </div>
                </button>
              </Link>
              <Link
                to={process.env.REACT_APP_PLAY_STORE_CRYPTO_URL}
                target="_blank"
              >
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={ANDROID_ICON} alt="icon" className="btn_img" />
                    <span>Android</span>
                  </div>
                </button>
              </Link>
            </div>
          </div>
          <div className="btn-flex">
            <Link
              to={process.env.REACT_APP_PLAY_STORE_CRYPTO_URL}
              target="_blank"
            >
              <button className="black-btn-small">Free Download</button>
            </Link>
            <Link
              to={process.env.REACT_APP_BLOG_PRODUCT_CRYPTO_URL}
              target="_blank"
            >
              <button className="black-btn-small">Buy Now</button>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default AboutWallet;
