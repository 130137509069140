import { Link } from "react-router-dom";
import {
  ANDROID_ICON,
  APPLE_ICON,
  DB_ABOUT_VECTOR,
  WEB_ICON,
} from "../../assets";

function DBAboutWallet() {
  return (
    <main className="DBaboutwallet-container">
      <section className="cryptowallet-flex">
        <div>
          <img src={DB_ABOUT_VECTOR} alt="vector" className="aboutwallet-img" />
        </div>
        <div className="cryptowallet-flex-col">
          <span className="DBwallet-smtitle">Doctor on demand App script</span>
          <span className="cryptowallet-title">Appointment booking System</span>
          <span className="cryptowallet-sub-title">
            Doctor on Demand is a powerful and efficient appointment booking
            system designed for healthcare providers to manage their
            appointments, patients and schedules effectively. With our Doctor on
            Demand readymade script, healthcare providers can easily create
            their own website and mobile apps to allow patients to book
            appointments with them directly.
          </span>
          <div className="cryptowallet-btn-text">
            <span className="cryptowallet-btn-text_">Checkout Live on</span>
            <div>
              <Link to={process.env.REACT_APP_IOS_DOCTOR_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={APPLE_ICON} alt="icon" className="btn_img" />
                    <span>iOS</span>
                  </div>
                </button>
              </Link>
              <Link
                to={process.env.REACT_APP_PLAY_STORE_DOCTOR_URL}
                target="_blank"
              >
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={ANDROID_ICON} alt="icon" className="btn_img" />
                    <span>Android</span>
                  </div>
                </button>
              </Link>
              <Link to={process.env.REACT_APP_WEB_DOCTOR_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={WEB_ICON} alt="icon" className="btn_img" />
                    <span>Web</span>
                  </div>
                </button>
              </Link>
            </div>
          </div>
          <div className="btn-flex">
            <Link
              to={process.env.REACT_APP_PLAY_STORE_DOCTOR_URL}
              target="_blank"
            >
              <button className="black-btn-small">Free Download</button>
            </Link>
            <Link to={process.env.REACT_APP_BLOG_DOCTOR_URL} target="_blank">
              <button className="black-btn-small">Buy Now</button>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default DBAboutWallet;
