import { DEDICATED_VECTOR } from "../../assets";

function TIKNewFeatures() {
  return (
    <main className="Dedicated-container">
      <section className="Dedicated-flex">
        <div>
          <img src={DEDICATED_VECTOR} alt="vector" className="AndroidIOS-img" />
        </div>
        <div className="Dedicated-flex-col">
          <span className="Dedicated-smtitle">Tiktok Like App Source Code</span>
          <span className="cryptowallet-title">Get a Free Quote</span>
          <span className="New-features-list">
            If you're interested in launching your own TikTok clone app,
            Skyclones can help! We offer a comprehensive, end-to-end solution
            for building and launching your own TikTok clone, complete with
            custom branding, features, and functionality. To get started, simply
            fill out our online form to request a free quote. Our team will
            review your project requirements and provide you with a customized
            quote tailored to your needs. Whether you're looking for a basic
            TikTok clone or a more advanced solution with custom features and
            integrations, we can help.
          </span>
        </div>
      </section>
    </main>
  );
}

export default TIKNewFeatures;
