import { useEffect } from "react";
import {
  Footer,
  RBWebPanel,
  RBTechnologies,
  RBNewFeatures,
  RBFreeDownload,
  RBCryptoWalletApp,
  RBCrateWallet,
  RBAndroidIOS,
  RBAboutWallet,
  RBAboutFeatures,
  Feedback,
  Layout,
} from "../components";

function RecipeBook() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Layout
        title="Food Recipe Book Readymade Script - Android & iOS | Get a Free Quote"
        metaTitle="Food Recipe Book Readymade Script - Android & iOS | Get a Free Quote"
        metaDesc="Get a readymade food recipe book script for Android and iOS with features like adding and managing recipes, user profiles, favorite recipes, admin panel, and more. Get a free quote now."
        metaKey=" food recipe book script, readymade food recipe book, Android, iOS, add recipe, user profile, admin panel, favorite recipes, get a free quote."
      />
      <RBCrateWallet />
      <RBAboutWallet />
      <RBAboutFeatures />
      <RBCryptoWalletApp />
      <RBWebPanel />
      <RBFreeDownload />
      <RBTechnologies />
      <RBAndroidIOS />
      <RBNewFeatures />
      <Feedback />
      <Footer />
    </>
  );
}

export default RecipeBook;
