import { WHATSAPP_ICON } from "../assets";

function WhatsApp() {
  return (
    <div className="whatsapp_fixed">
      <a
        href={process.env.REACT_APP_WHATSAPP_URL}
        rel="noreferrer"
        target="_blank"
      >
        <img
          className="icon-socl"
          src={WHATSAPP_ICON}
          width="55"
          height="55"
          alt="icon"
        />
      </a>
    </div>
  );
}

export default WhatsApp;
