import { Link } from "react-router-dom";
import {
  ANDROID_ICON,
  APPLE_ICON,
  TIKTIK_VECTOR,
  WEB_ICON,
} from "../../assets";

function Tiktik() {
  return (
    <main className="Tiktik-container TIK_about_BG_">
      <section className="Tiktik-flex">
        <div>
          <img src={TIKTIK_VECTOR} alt="vector" className="cryptowallet-img" />
        </div>
        <div className="Tiktik-flex-col">
          <span className="Tiktik-smtitle">Free Tiktok ReadyMade Script</span>
          <Link to="/tiktok-clone" className="cryptowallet-title">
            Tiktok Readymade Script
          </Link>
          <span className="cryptowallet-sub-title">
            Skyclones' TikTok Readymade Script is a complete solution for
            building a TikTok-like app for Android and iOS. Our script comes
            with a range of features, including video creation and editing
            tools, social media integration, and more. With our script, you can
            create your own social media platform quickly and easily. Create
            your own social media platform today with Skyclones' TikTok
            Readymade Script and start building the next big thing in social
            media.
          </span>
          <div className="cryptowallet-btn-text">
            <span className="cryptowallet-btn-text_">Checkout Live on</span>
            <div>
              <Link to={process.env.REACT_APP_IOS_TIKTIK_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={APPLE_ICON} alt="icon" className="btn_img" />
                    <span>iOS</span>
                  </div>
                </button>
              </Link>
              <Link
                to={process.env.REACT_APP_PLAY_STORE_TIKTIK_URL}
                target="_blank"
              >
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={ANDROID_ICON} alt="icon" className="btn_img" />
                    <span>Android</span>
                  </div>
                </button>
              </Link>
              <Link
                to={process.env.REACT_APP_WEB_TIKTIK_URL}
                target="_blank"
              >
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={WEB_ICON} alt="icon" className="btn_img" />
                    <span>Web</span>
                  </div>
                </button>
              </Link>
            </div>
          </div>
          <div className="btn-flex">
            <Link
              to={process.env.REACT_APP_PLAY_STORE_TIKTIK_URL}
              target="_blank"
            >
              <button className="black-btn-small">Free Download</button>
            </Link>
            <Link to={process.env.REACT_APP_BLOG_TIKTIK_URL} target="_blank">
              <button className="black-btn-small">Buy Now</button>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Tiktik;
