import { Fragment } from "react";
import { Routes, Route } from "react-router-dom";
import { Navbar, ScrollTop, WhatsApp } from "../components";
import {
  Home,
  CryptoWallet,
  DoctorBooking,
  InstaSoft,
  TikTik,
  RecipeBook,
  SiteMap,
  DynamicPage,
  CarRental,
  // BNBWallet,
  // Test,
  // SwiftUIControl,
} from "../pages";

function AppRoute() {
  return (
    <Fragment>
      <ScrollTop />
      <Navbar />
      <div id="top-m">
        <div className="vertical-text">Follo Us - Dr. / Be. / Ig. / fb. </div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="crypto-wallet" element={<CryptoWallet />} />
          <Route path="doctor-on-demand" element={<DoctorBooking />} />
          <Route path="instagram-clone" element={<InstaSoft />} />
          <Route path="tiktok-clone" element={<TikTik />} />
          <Route path="food-recipe-book" element={<RecipeBook />} />
          <Route path="sitemap" element={<SiteMap />} />
          <Route path="dynamicpage" element={<DynamicPage />} />
          <Route path="car-rental-script-download" element={<CarRental />} />

          {/* <Route path="/BNBWallet" element={<BNBWallet />} /> */}
          {/* <Route path="/SwiftUI" element={<SwiftUIControl />} /> */}
          {/* <Route path="/Test" element={<Test />} /> */}
        </Routes>
      </div>
      <WhatsApp />
    </Fragment>
  );
}

export default AppRoute;
