import { Link } from "react-router-dom";
import { DEDICATED_VECTOR } from "../../assets";

function DedicatedSupport() {
  return (
    <main className="Dedicated-container">
      <section className="Dedicated-flex">
        <div>
          <img
            src={DEDICATED_VECTOR}
            alt="vector"
            className="cryptowallet-img"
          />
        </div>
        <div className="Dedicated-flex-col">
          <span className="Dedicated-smtitle">
            Skyclone's 24/7/365 Live Support
          </span>
          <span className="cryptowallet-title">Dedicated Support 24/7/365</span>
          <span className="cryptowallet-sub-title">
            Skyclones' Dedicated Support is a service that provides ongoing
            assistance and guidance to help you get the most out of our products
            and services. Our team of experts is available around the clock to
            answer your questions, provide technical support, and help you
            optimize your mobile app. Get started with Dedicated Support today
            and see the difference it can make for your business. Our team is
            standing by to help you create better mobile apps and achieve your
            business objectives.
          </span>
          <Link
            to={process.env.REACT_APP_BLOG_CONTACT_URL}
            target="_blank"
            className="btn-flex"
          >
            <button className="black-btn-small">Inquire Now</button>
          </Link>
        </div>
      </section>
    </main>
  );
}

export default DedicatedSupport;
