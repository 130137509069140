import {
  IS_YOUR_FRIEND_ICON,
  IS_PROFILE_PHOTOS_ICON,
  IS_NOTIFICATION_ICON,
  IS_FOLLOW_FRIEND_ICON,
  IS_PRICACY_ICON,
  IS_LIKE_ICON,
} from "../../assets";

function ISAboutFeatures() {
  return (
    <main className="ISAboutFeatures-container">
      <div className="AboutFeatures-title">
        Instagram Readymade Script Advance Features.
      </div>
      <div>
        <div className="AboutFeatures-wrapper">
          {AboutFeaturesData.map((item, index) => {
            return (
              <div className="AboutFeatures-box" key={index}>
                <div className="AboutFeatures-card">
                  <div className="AboutFeatures-img-flex">
                    <div className="ISAboutFeatures-img-box">
                      <img
                        src={item?.IMG}
                        alt="icon"
                        className="ISAboutFeatures-img_"
                      />
                    </div>
                    <div className="AboutFeatures-subtitle ">{item?.Title}</div>
                  </div>
                  <div className="AboutFeatures-info">{item?.describe}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
}
export default ISAboutFeatures;

const AboutFeaturesData = [
  {
    IMG: IS_YOUR_FRIEND_ICON,
    Title: "Search your friends",
    describe:
      "The Instagram clone script comes with a feature to easily search for your friends on the platform, making it easier to connect and interact with them. The search functionality is intuitive and provides accurate results, enabling users to find their friends quickly and efficiently. ",
  },
  {
    IMG: IS_PROFILE_PHOTOS_ICON,
    Title: "Explore public profile photos",
    describe:
      "With our Instagram clone, you can easily explore public profile photos of other users. This feature allows you to discover new content and connect with others on the platform. Whether you're looking for inspiration, entertainment, or networking opportunities, our Instagram clone has got you covered. With just a few clicks, you can access a vast library of public photos from all around the world.",
  },
  {
    IMG: IS_FOLLOW_FRIEND_ICON,
    Title: "follow your friends",
    describe:
      "Our Instagram clone script allows users to easily follow their friends and stay updated with their latest posts. With just a few clicks, users can follow their friends and get access to their content, including photos and videos. Our script also includes features like suggested users and popular accounts to help users discover new content and connect with new people on the platform. Stay connected with your friends and the wider Instagram community with our readymade script.",
  },
  {
    IMG: IS_LIKE_ICON,
    Title: "Comment, like and bookmark",
    describe:
      "The Instagram clone script offers users the ability to engage with content through commenting, liking, and bookmarking. Users can express their thoughts and feelings about images and videos posted by other users, as well as save content for later viewing. This feature encourages user interaction and increases engagement within the platform, making it a valuable tool for social networking and content sharing.",
  },
  {
    IMG: IS_PRICACY_ICON,
    Title: "Change your pricacy",
    describe:
      "Our Instagram clone script offers the ability to change your privacy settings, giving users complete control over who can see their profile and content. Whether you want to keep your profile public, limit it to just your followers, or even create a private account for only close friends, our script provides the flexibility to choose the level of privacy that suits you best.",
  },
  {
    IMG: IS_NOTIFICATION_ICON,
    Title: "Notification for activity",
    describe:
      "With the notification and activities feature of our Instagram clone script, you'll never miss out on any updates from your followers or people you follow. Stay in the loop with real-time notifications for new followers, likes, comments, and direct messages. Get notified about any activity on your profile, as well as any activity on the profiles of the people you follow. With our Instagram clone script, you'll always be on top of your social media game.",
  },
];
