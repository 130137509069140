import { IS_PANEL_VECTOR } from "../../assets";

function TIKWebPanel() {
  return (
    <main className="WebPanel-container">
      <div className="WebPanel-title">Tiktok Readymade Script Web Panel</div>
      <div className="cwa-subtitle">
        At Skyclones, we understand the importance of having a powerful admin
        panel to manage your app's content and user base. That's why our TikTok
        clone comes with a comprehensive admin panel that allows you to manage
        your content, users, and app settings with ease. You can view detailed
        analytics, track user activity, and make changes to your app as needed.
      </div>
      <img src={IS_PANEL_VECTOR} alt="icon" className="webpanel-img" />
    </main>
  );
}

export default TIKWebPanel;
