function DBFreeDownload() {
  return (
    <main className="BNB_Free-container">
      <div className="cwa-title">Doctor on Demand Readymade Script Video</div>
      <div className="cwa-subtitle">
        Our Doctor on Demand Readymade Script is a comprehensive solution for
        startups looking to establish an online appointment booking system for
        doctors. The script is compatible with web and mobile platforms,
        providing patients with the convenience of booking appointments from
        anywhere.
      </div>
      <iframe
        title="BNB"
        width="0px"
        height="600px"
        src={process.env.REACT_APP_YOUTUBE_DOCTOR_URL}
      ></iframe>
    </main>
  );
}

export default DBFreeDownload;
