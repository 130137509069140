import { useEffect } from "react";
import {
  AboutFeatures,
  AboutWallet,
  AndroidIOS,
  CrateWallet,
  CryptoWalletApp,
  Feedback,
  Footer,
  FreeDownload,
  Layout,
  NewFeatures,
  Technologies,
  WebPanel,
} from "../components";

function CryptoWallet() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Layout
        title="Create Your Own Crypto Wallet with Skyclones.com | Crypto Wallet Readymade Script Download"
        metaTitle="Create Your Own Crypto Wallet with Skyclones.com | Crypto Wallet Readymade Script Download"
        metaDesc="Build your own secure and reliable crypto wallet with Skyclones.com's readymade script. Download our Crypto Wallet script and start managing your cryptocurrencies with ease."
        metaKey="crypto wallet, cryptocurrency, Skyclones, readymade script, download, manage cryptocurrencies, secure, reliable."
      />
      <CrateWallet />
      <AboutWallet />
      <AboutFeatures />
      <CryptoWalletApp />
      <WebPanel />
      <FreeDownload />
      <Technologies />
      <AndroidIOS />
      <NewFeatures />
      <Feedback />
      <Footer />
    </>
  );
}

export default CryptoWallet;
