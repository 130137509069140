function TIKFreeDownload() {
  return (
    <main className="IS_Free-container">
      <div className="cwa-title">Tiktok Readymade App Script Video</div>
      <div className="cwa-subtitle">
        Our TikTok clone script comes with a wide range of features and
        functionality that users expect from a social video app. This includes
        the ability to create and share short videos, follow other users, add
        effects and filters, and much more. The app is designed with a
        user-friendly interface and customizable design, allowing you to tailor
        your app to your specific brand and audience.
      </div>
      <iframe
        title="BNB"
        width="0px"
        height="600px"
        src={process.env.REACT_APP_YOUTUBE_TIKTIK_URL}
      ></iframe>
    </main>
  );
}

export default TIKFreeDownload;
