import {
  T_ANDROID_ICON,
  T_IOS_ICON,
  T_KOTLIN_ICON,
  T_REACT_ICON,
  T_WEB_ICON,
} from "../../assets";

function Technologies() {
  return (
    <main className="Technologies-container">
      <div className="cwa-title">Technologies</div>
      <div className="cwa-subtitle">
        Skyclones' Crypto Wallet is built using the latest technologies to
        provide you with a fast, secure, and reliable platform for managing your
        digital assets. Our wallet is built on a blockchain-based
        infrastructure, ensuring that your transactions are secure and
        tamper-proof, with no intermediaries or third parties involved. We also
        use advanced encryption and authentication technologies to ensure that
        your funds and personal data are kept safe at all times. Our wallet
        supports multiple cryptocurrencies and custom tokens, making it easy to
        manage all of your digital assets in one place. With Skyclones' Crypto
        Wallet, you can have confidence that your digital assets are in good
        hands, backed by the latest and most advanced technologies available
        today.
      </div>
      <div>
        <div className="Technologies-wrapper">
          <div className="Technologies-box">
            <img src={T_ANDROID_ICON} alt="icon" />
            <span>Android</span>
          </div>
          <div className="Technologies-box">
            <img src={T_IOS_ICON} alt="icon" />
            <span>ios</span>
          </div>
          <div className="Technologies-box">
            <img src={T_KOTLIN_ICON} alt="icon" />
            <span>Kotlin</span>
          </div>
          <div className="Technologies-box">
            <img src={T_WEB_ICON} alt="icon" />
            <span>Web</span>
          </div>
          <div className="Technologies-box">
            <img src={T_REACT_ICON} alt="icon" />
            <span>React</span>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Technologies;
