import React from 'react'

function Technologie({ technologie }) {
    return (
        <main className="Technologies-container">
            <div className="WebPanel-title">{technologie?.title}</div>
            <div className="cwa-subtitle">
                {technologie?.subtitle}
            </div>
            <div>
                <div className="Technologies-wrapper">
                    <div className="Technologies-box">
                        <img src={technologie?.icon1} alt="icon" />
                        <span>{technologie?.title1}</span>
                    </div>
                    <div className="Technologies-box">
                        <img src={technologie?.icon2} alt="icon" />
                        <span>{technologie?.title2}</span>
                    </div>
                    <div className="Technologies-box">
                        <img src={technologie?.icon3} alt="icon" />
                        <span>{technologie?.title3}</span>
                    </div>
                    <div className="Technologies-box">
                        <img src={technologie?.icon4} alt="icon" />
                        <span>{technologie?.title4}</span>
                    </div>
                    <div className="Technologies-box">
                        <img src={technologie?.icon5} alt="icon" />
                        <span>{technologie?.title5}</span>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Technologie