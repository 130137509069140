import { useEffect } from "react";
import {
  Footer,
  TIKWebPanel,
  TIKTechnologies,
  TIKNewFeatures,
  TIKFreeDownload,
  TIKCryptoWalletApp,
  TIKCrateWallet,
  TIKAndroidIOS,
  TIKAboutWallet,
  TIKAboutFeatures,
  Feedback,
  Layout,
} from "../components";

function TikTik() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Layout
        title="TikTok Clone Script - Ready-made TikTok Clone App for Android and iOS"
        metaTitle="TikTok Clone Script - Ready-made TikTok Clone App for Android and iOS"
        metaDesc="Get a custom TikTok clone script with advanced features, intuitive design, and robust technology. Launch your own social media platform with our ready-to-use TikTok clone app for Android and iOS."
        metaKey="TikTok clone script, TikTok clone app, TikTok clone, social media platform, ready-made clone, Android, iOS, custom clone, advanced features, intuitive design, robust technology."
      />
      <TIKCrateWallet />
      <TIKAboutWallet />
      <TIKAboutFeatures />
      <TIKCryptoWalletApp />
      <TIKWebPanel />
      <TIKFreeDownload />
      <TIKTechnologies />
      <TIKAndroidIOS />
      <TIKNewFeatures />
      <Feedback />
      <Footer />
    </>
  );
}

export default TikTik;
