import { HelmetProvider } from "react-helmet-async";
// import { Helmet } from "react-helmet-async";
import { Helmet } from "react-helmet";

// const createTitle = (title) => {
//   return Array.isArray(title)
//     ? `${title.join(" - ")} - sky clones`
//     : `${title + " - "}  sky clones`;
// };

const Layout = ({ metaTitle, metaDesc, metaKey, title }) => {
  let Title =
    title || "On-Demand Mobile App Development and Ready-to-Use Scripts";
  let MetaTitle =
    metaTitle || "On-Demand Mobile App Development and Ready-to-Use Scripts";
  let MetaDesc =
    metaDesc ||
    "Skyclones offers on-demand mobile app development for Android and iOS, as well as ready-to-use scripts to create apps like TikTok, Instagram, and more. Contact us today to learn more!";
  let MetaKey =
    metaKey ||
    "on-demand mobile app development, ready-to-use scripts, Skyclones, Tiktok Script, Intagram Script, Readymade Script, Doctor on demand script, Shopify to Mobile App convertor";

  // const createdTitleText = createTitle(Title);

  return (
    <HelmetProvider>
      <Helmet /* htmlAttributes */>
        <title>{Title}</title>
        {/* <!--  =========================================== META Tag ========================================================  --> */}

        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="keywords" content={MetaKey} />
        <meta name="description" content={MetaDesc} />

        {/* <!--  =========================================== FB Tag ========================================================  --> */}

        <meta property="og:title" content={MetaTitle} />
        <meta property="og:type" content="Website" />
        <meta property="og:url" content="http://skyclones.com/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta content={MetaDesc} name="description" />

        {/* <!--  =========================================== TWITTER Tag ====================================================  --> */}

        <meta name="twitter:title" content={MetaTitle} />
        <meta name="twitter:description" content={MetaDesc} />
        <meta name="twitter:card" content="http://skyclones.com/" />
      </Helmet>
    </HelmetProvider>
  );
};

export default Layout;
