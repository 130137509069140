import { DEDICATED_VECTOR } from "../../assets";

function NewFeatures() {
  return (
    <main className="Dedicated-container">
      <section className="Dedicated-flex">
        <div>
          <img src={DEDICATED_VECTOR} alt="vector" className="AndroidIOS-img" />
        </div>
        <div className="Dedicated-flex-col">
          <span className="Dedicated-smtitle">Crypto Wallet Maker</span>
          <span className="cryptowallet-title">GET FREE QUOTE</span>
          <span className="New-features-list">
            Skyclones' Crypto Wallet also offers the option for a custom quote,
            providing you with a personalized pricing plan tailored to your
            specific needs and requirements. If you're looking for a customized
            crypto wallet solution that meets your unique business needs, we're
            here to help. Our team of experienced developers and engineers can
            work with you to design and develop a custom wallet solution that
            fits your exact specifications. Whether you need additional security
            features, customized user interfaces, or integrations with
            third-party services, we can help you build a crypto wallet that
            works for you. To get started, simply contact us for a free custom
            quote, and our team will work with you to create a personalized plan
            that meets your needs and budget.
          </span>
        </div>
      </section>
    </main>
  );
}

export default NewFeatures;
