import { Link } from "react-router-dom";
import { APPLE_ICON, SWIFTUI_CONTROLS_VECTOR } from "../../assets";

function SwiftuiControls() {
  return (
    <main className="SwiftuiControls-container SwiftuiControls_about_BG">
      <section className="SwiftuiControls-flex">
        <div className="SwiftuiControls-flex-col">
          <span className="SwiftuiControls-smtitle">
            Free Swift UI Controls
          </span>
          <span className="cryptowallet-title">SWIFTUI CONTROLS</span>
          <span className="cryptowallet-sub-title">
            Skyclones' Free Shift UI Control is a ready-to-use user interface
            (UI) kit designed to help developers create beautiful and intuitive
            mobile apps quickly and easily. With a range of pre-built components
            and styles, our UI control simplifies the app development process
            and helps you create apps that look and feel great. Download
            Skyclones' Free Shift UI Control today and start creating mobile
            apps that look and feel great.
          </span>
          <div className="cryptowallet-btn-text">
            <span className="cryptowallet-btn-text_">Checkout Live on</span>
            <div>
              <Link to={process.env.REACT_APP_IOS_SWIFTUI_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={APPLE_ICON} alt="icon" className="btn_img" />
                    <span>iOS</span>
                  </div>
                </button>
              </Link>
              {/* <button className="blue-btn-small">
                <div className="btn_align">
                  <img src={ANDROID_ICON} alt="icon" className="btn_img" />
                  <span>Android</span>
                </div>
              </button> */}
            </div>
          </div>
          <div className="btn-flex">
            <Link to={process.env.REACT_APP_PLAY_STORE_SWIFTUI_URL} target="_blank">
              <button className="black-btn-small">Free Download</button>
            </Link>
            <Link to={process.env.REACT_APP_BLOG_SWIFTUI_URL} target="_blank">
              <button className="black-btn-small">Buy Now</button>
            </Link>
          </div>
        </div>
        <div className="SwiftuiControls_img">
          <img
            src={SWIFTUI_CONTROLS_VECTOR}
            alt="vector"
            className="cryptowallet-img"
          />
        </div>
      </section>
    </main>
  );
}

export default SwiftuiControls;
