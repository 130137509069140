import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination } from 'swiper/modules';
import { CR_DASHBORD_1, CR_DASHBORD_2, CR_DASHBORD_3, CR_DASHBORD_4, CR_DASHBORD_5 } from '../../assets';

function AppOverviewDesk({ appOverview }) {


    return (
        <main className="cwa-container lg:my-10">
            <div className="cwa-title">{appOverview?.title}</div>
            <div className="cwa-subtitle">
                {appOverview?.subtitle}
            </div>
            <Swiper
                slidesPerView={'auto'}
                centeredSlides={true}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img src={CR_DASHBORD_1} alt="icon" className="webpanel-img" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={CR_DASHBORD_2} alt="icon" className="webpanel-img" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={CR_DASHBORD_3} alt="icon" className="webpanel-img" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={CR_DASHBORD_4} alt="icon" className="webpanel-img" />
                </SwiperSlide>
                <SwiperSlide>
                    <img src={CR_DASHBORD_5} alt="icon" className="webpanel-img" />
                </SwiperSlide>
            </Swiper>
        </main>
    );
}

export default AppOverviewDesk

