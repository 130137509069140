import {
  T_ANDROID_ICON,
  T_IOS_ICON,
  T_KOTLIN_ICON,
  T_REACT_ICON,
  T_WEB_ICON,
} from "../../assets";

function RBTechnologies() {
  return (
    <main className="Technologies-container">
      <div className="WebPanel-title">Technologies</div>
      <div className="cwa-subtitle">
        The food recipe book readymade script for Android and iOS is built using
        the latest technologies and frameworks such as React Native, Firebase,
        and Node.js. These technologies ensure that the app is reliable, fast,
        and scalable, providing users with a seamless experience. The app is
        designed to be user-friendly and easy to navigate, making it the perfect
        solution for anyone looking to create their own recipe book app.
      </div>
      <div>
        <div className="Technologies-wrapper">
          <div className="Technologies-box">
            <img src={T_ANDROID_ICON} alt="icon" />
            <span>Android</span>
          </div>
          <div className="Technologies-box">
            <img src={T_IOS_ICON} alt="icon" />
            <span>ios</span>
          </div>
          <div className="Technologies-box">
            <img src={T_KOTLIN_ICON} alt="icon" />
            <span>Kotlin</span>
          </div>
          <div className="Technologies-box">
            <img src={T_WEB_ICON} alt="icon" />
            <span>Web</span>
          </div>
          <div className="Technologies-box">
            <img src={T_REACT_ICON} alt="icon" />
            <span>React</span>
          </div>
        </div>
      </div>
    </main>
  );
}

export default RBTechnologies;
