import React from 'react'
import { WEB_ICON } from '../../assets'
import { Link } from 'react-router-dom'

function AboutUS({ aboutus }) {
    return (
        <main className="BNBaboutwallet-container" style={{ backgroundColor: `${aboutus?.backgroundColor}` }}>
            <section className="cryptowallet-flex">
                <div>
                    <img
                        src={aboutus?.vector}
                        alt="vector"
                        className="aboutwallet-img"
                    />
                </div>
                <div className="cryptowallet-flex-col">
                    <span className="BNBwallet-smtitle" style={{ color: `${aboutus?.aboutTextcolor}` }} >{aboutus?.aboutTagline}</span>
                    <span className="cryptowallet-title">{aboutus?.title}</span>
                    <span className="cryptowallet-sub-title">
                        {aboutus?.subtitle}
                    </span>
                    <div className="cryptowallet-btn-text">
                        <span className="cryptowallet-btn-text_">Checkout Live on</span>
                        <div>
                            <Link to={aboutus?.webLink} target="_blank">
                                <button className="blue-btn-small">
                                    <div className="btn_align">
                                        <img src={WEB_ICON} alt="icon" className="btn_img" />
                                        <span>Web</span>
                                    </div>
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="btn-flex">
                        {aboutus?.freeDownloadLink &&
                            <Link to={aboutus?.freeDownloadLink} target="_blank">
                                <button className="black-btn-small">Free Download</button>
                            </Link>}
                        <Link
                            to={aboutus?.buyLink}
                            target="_blank"
                        >
                            <button className="black-btn-small">Buy Now</button>
                        </Link>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default AboutUS