import React from "react";
import {
  TIK_WA_VECTOR,
  TIK_PHONE1,
  TIK_PHONE2,
  TIK_PHONE3,
  TIK_PHONE4,
  TIK_PHONE5,
} from "../../assets";
import { Slider } from "../Slider";

function TIKCryptoWalletApp() {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const slideNext = (e) => {
    setCurrentSlide((prev) => {
      return prev + 1 === slides.length ? 0 : currentSlide + 1;
    });
  };
  const slidePrev = (e) => {
    setCurrentSlide((prev) => {
      return prev === 0 ? slides.length - 1 : currentSlide - 1;
    });
  };
  React.useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prev) => {
        return prev + 1 === slides.length ? 0 : prev + 1;
      });
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <main className="cwa-container">
      <div className="cwa-title">Tiktok Clone Script</div>
      <div className="cwa-subtitle">
        Best of all, our TikTok clone is available at an affordable price,
        making it a great choice for startups and small businesses. For just
        $99, you can get your own TikTok clone script and start building your
        own social video app today. And if you have any questions or need
        support, our dedicated team is here to help you every step of the way.
        Ready to launch your own social video app? Get started with Skyclones'
        TikTok clone script today!
      </div>
      <img src={TIK_WA_VECTOR} alt="icon" className="img_sh" />
      <div className="Slider_sh">
        <Slider
          image={slides[currentSlide]}
          slideNext={slideNext}
          slidePrev={slidePrev}
        />
      </div>
    </main>
  );
}
export default TIKCryptoWalletApp;

const slides = [
  {
    link: TIK_PHONE1,
  },
  {
    link: TIK_PHONE2,
  },
  {
    link: TIK_PHONE3,
  },
  {
    link: TIK_PHONE4,
  },
  {
    link: TIK_PHONE5,
  },
];
