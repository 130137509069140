import React from 'react'
// import { BNB_PHONE1 } from '../../assets';
import { Slider } from '../Slider';

function AppOverview({ appOverview }) {
    const [currentSlide, setCurrentSlide] = React.useState(0);

    const slideNext = (e) => {
        setCurrentSlide((prev) => {
            return prev + 1 === appOverview?.vector1.length ? 0 : currentSlide + 1;
        });
    };
    const slidePrev = (e) => {
        setCurrentSlide((prev) => {
            return prev === 0 ? appOverview?.vector1.length - 1 : currentSlide - 1;
        });
    };
    React.useEffect(() => {
        const intervalId = (() => {
            setCurrentSlide((prev) => {
                return prev + 1 === appOverview?.vector1.length ? 0 : prev + 1;
            });
        }, 3000);
        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line
    }, [])

    return (
        <main className="cwa-container lg:my-10">
            <div className="cwa-title">Crypto your bnb wallet App Now</div>
            <div className="cwa-subtitle">
                Crypto Wallet is the best choice for businesses looking to securely
                store and manage their digital coins and NFTs on the Binance Chain. So
                why wait? Get started
            </div>
            <img src={appOverview?.vector} alt="icon" className="img_sh" />
            <div className="Slider_sh">
                <Slider
                    image={appOverview?.vector1[currentSlide]}
                    slideNext={slideNext}
                    slidePrev={slidePrev}
                />
            </div>
        </main>
    );
}

export default AppOverview

// const slides = [
//     {
//         link: BNB_PHONE1,
//     },
//     {
//         link: BNB_PHONE1,
//     },
//     {
//         link: BNB_PHONE1,
//     },
//     {
//         link: BNB_PHONE1,
//     },
//     {
//         link: BNB_PHONE1,
//     },
// ];
