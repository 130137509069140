import { PREVIOUS_ICON } from "../assets";

export const Slider = (props) => {
  return (
    <>
      <div className="slider-flex">
        <div onClick={props.slidePrev}>
          <img src={PREVIOUS_ICON} alt="icon" style={{ marginRight: "20px" }} />
        </div>
        <img src={props.image.link} alt="Sliderr_image" />
        <div onClick={props.slideNext}>
          <img
            src={PREVIOUS_ICON}
            alt="icon"
            style={{ transform: "rotate(-180deg)", marginLeft: "20px" }}
          />
        </div>
      </div>
    </>
  );
};
