function ISFreeDownload() {
  return (
    <main className="IS_Free-container">
      <div className="cwa-title">Instagram Script Free Download Video</div>
      <div className="cwa-subtitle">
        In short, our Instagram clone is the perfect solution for startups
        looking to enter the social media space. With our readymade script, you
        can launch your own platform quickly and easily, without having to worry
        about the technical complexities of app development. Get in touch with
        us today to learn more about our Instagram clone and how it can benefit
        your business!
      </div>
      <iframe
        title="BNB"
        width="0px"
        height="600px"
        src={process.env.REACT_APP_YOUTUBE_INSTA_URL}
      ></iframe>
    </main>
  );
}

export default ISFreeDownload;
