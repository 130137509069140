import React from 'react'
import { Link } from 'react-router-dom'
import { APPLE_FILL_ICON, PLAY_STORE_ICON } from '../../assets'

function DownloadSection({ downloadSection, Button }) {
    return (
        <main className="BNBAndroidIOS-container">
            <section className="AndroidIOS-flex">
                <div className="AndroidIOS-flex-col">
                    <span className="AndroidIOS-title">
                        {downloadSection?.title}
                    </span>
                    <div className="AndroidIOS_info">
                        {downloadSection?.subtitle}
                    </div>
                    <div className="AndroidIOS-button">
                        {downloadSection?.GooglePlayLink &&
                            <Link to={downloadSection?.GooglePlayLink} target="_blank">
                                <button
                                    className="AndroidIOS-btn"
                                    style={{ marginRight: "15px" }}
                                >
                                    <div className="btn_align">
                                        <img src={PLAY_STORE_ICON} alt="icon" className="btn_img" />
                                        <div className="AndroidIOS-btn-flex">
                                            <span className="AndroidIOS-btn-text">DOWNLOAD FOR</span>
                                            <span className="AndroidIOS-btn-text_">Google Play</span>
                                        </div>
                                    </div>
                                </button>
                            </Link>}
                        {downloadSection?.AppStoreLink &&
                            <Link to={downloadSection?.AppStoreLink} target="_blank">
                                <button className="AndroidIOS-btn">
                                    <div className="btn_align">
                                        <img src={APPLE_FILL_ICON} alt="icon" className="btn_img" />
                                        <div className="AndroidIOS-btn-flex">
                                            <span className="AndroidIOS-btn-text">DOWNLOAD FOR</span>
                                            <span className="AndroidIOS-btn-text_">App Store</span>
                                        </div>
                                    </div>
                                </button>
                            </Link>}
                        {downloadSection?.webLiveLink &&
                            <Link to={downloadSection?.webLiveLink} target="_blank">
                                <button
                                    className="AndroidIOS-btn"
                                    style={{ marginRight: "15px" }}
                                >
                                    <div className="btn_align">
                                        {/* <img src={PLAY_STORE_ICON} alt="icon" className="btn_img" /> */}
                                        <div className="AndroidIOS-btn-flex">
                                            {/* <span className="AndroidIOS-btn-text">Live demo</span> */}
                                            <span className="AndroidIOS-btn-text_">Live demo</span>
                                        </div>
                                    </div>
                                </button>
                            </Link>}
                    </div>

                </div>
                <div>
                    <img src={downloadSection?.vector} alt="vector" className="AndroidIOS-img" />
                </div>
            </section>
        </main>
    )
}

export default DownloadSection