import { Link } from "react-router-dom";
import {
  ANDROID_ICON,
  APPLE_ICON,
  TIK_ABOUT_VECTOR,
  WEB_ICON,
} from "../../assets";

function TIKAboutWallet() {
  return (
    <main className="TIKaboutwallet-container">
      <section className="cryptowallet-flex">
        <div>
          <img
            src={TIK_ABOUT_VECTOR}
            alt="vector"
            className="aboutwallet-img"
          />
        </div>
        <div className="cryptowallet-flex-col">
          <span className="TIKwallet-smtitle">Tiktok App Source Code</span>
          <span className="cryptowallet-title">Free Tiktok Clone Script</span>
          <span className="cryptowallet-sub-title">
            Welcome to Skyclones, your one-stop destination for readymade app
            solutions. We are proud to offer a TikTok clone script that allows
            you to quickly and easily launch your own social video app on
            Android and iOS platforms. Our TikTok clone is designed to help
            entrepreneurs and businesses enter the social video market with a
            robust and feature-rich app that engages users and drives
            growth.Ready to launch your own social video app? Get started with
            Skyclones' TikTok clone script today!
          </span>
          <div className="cryptowallet-btn-text">
            <span className="cryptowallet-btn-text_">Checkout Live on</span>
            <div>
              <Link to={process.env.REACT_APP_IOS_TIKTIK_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={APPLE_ICON} alt="icon" className="btn_img" />
                    <span>iOS</span>
                  </div>
                </button>
              </Link>
              <Link
                to={process.env.REACT_APP_PLAY_STORE_TIKTIK_URL}
                target="_blank"
              >
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={ANDROID_ICON} alt="icon" className="btn_img" />
                    <span>Android</span>
                  </div>
                </button>
              </Link>
              <Link to={process.env.REACT_APP_WEB_TIKTIK_URL} target="_blank">
                <button className="blue-btn-small">
                  <div className="btn_align">
                    <img src={WEB_ICON} alt="icon" className="btn_img" />
                    <span>Web</span>
                  </div>
                </button>
              </Link>
            </div>
          </div>
          <div className="btn-flex">
            <Link
              to={process.env.REACT_APP_PLAY_STORE_TIKTIK_URL}
              target="_blank"
            >
              <button className="black-btn-small">Free Download</button>
            </Link>
            <Link to={process.env.REACT_APP_BLOG_TIKTIK_URL} target="_blank">
              <button className="black-btn-small">Buy Now</button>
            </Link>
          </div>
        </div>
      </section>
    </main>
  );
}

export default TIKAboutWallet;
