import { Link } from "react-router-dom";
import { APPLE_FILL_ICON, CRYPTO_VECTOR, PLAY_STORE_ICON } from "../../assets";

function AndroidIOS() {
  return (
    <main className="AndroidIOS-container">
      <section className="AndroidIOS-flex">
        <div className="AndroidIOS-flex-col">
          <span className="AndroidIOS-title">Download Crypto Wallet Now!</span>
          <div className="AndroidIOS_info">
            Available on Android and IOS. Download Free Crypto Wallet App Source
            Code from Here.
          </div>
          <div className="AndroidIOS-button">
            <Link to={process.env.REACT_APP_PLAY_STORE_CRYPTO_URL} target="_blank">
              <button
                className="AndroidIOS-btn"
                style={{ marginRight: "15px" }}
              >
                <div className="btn_align">
                  <img src={PLAY_STORE_ICON} alt="icon" className="btn_img" />
                  <div className="AndroidIOS-btn-flex">
                    <span className="AndroidIOS-btn-text">DOWNLOAD FOR</span>
                    <span className="AndroidIOS-btn-text_">Google Play</span>
                  </div>
                </div>
              </button>
            </Link>
            <Link to={process.env.REACT_APP_IOS_CRYPTO_URL} target="_blank">
              <button className="AndroidIOS-btn">
                <div className="btn_align">
                  <img src={APPLE_FILL_ICON} alt="icon" className="btn_img" />
                  <div className="AndroidIOS-btn-flex">
                    <span className="AndroidIOS-btn-text">DOWNLOAD FOR</span>
                    <span className="AndroidIOS-btn-text_">App Store</span>
                  </div>
                </div>
              </button>
            </Link>
          </div>
        </div>
        <div>
          <img src={CRYPTO_VECTOR} alt="vector" className="AndroidIOS-img" />
        </div>
      </section>
    </main>
  );
}
export default AndroidIOS;
