import { Link } from "react-router-dom";
import {
  LOGO,
  SIDE_MENU_CLOSE,
  SIDE_MENU_ICON,
  SIDE_MENU_LIST_ICON,
} from "../assets";
import { useState } from "react";
import MainMenu from "./menus/mainMenu";

function Navbar() {
  const [mainMenuOpen, setMainMenuOpen] = useState(false);
  const [expand, setExpand] = useState([]);

  const handleQueToggle = (idx) => {
    if (expand.includes(idx)) setExpand(expand.filter((i) => i !== idx));
    else setExpand([...expand, idx]);
  };
  const QueToggle = (i) => expand.includes(i);
  function onTidioChatApiReady() {
    window.tidioChatApi.open();
    return;
  }
  return (
    <>
      <header className="navbar">
        <Link to="/" className="nav_logo">
          <img src={LOGO} alt="logo" />
        </Link>
        <div className="navbar-flex_">
          <div className="navbar_flex_">
            {/* ============================================= WEB CLONES =============================== */}
            <div className="dropdown">
              <button className="dropbtn">
                <div className="navbar-menu">Ready to Use</div>
              </button>
              <div className="dropdown-content">
                <div className="nav_black_menu">
                  <div>
                    <h1 className="nav_list_text">Ready to Use</h1>
                    <div className="navbar-wrapper">
                      {ReadyUseData.map((item, index) => {
                        return (
                          <Link to={item?.Link} key={index}>
                            <span className="linkH">{item?.SubTitle}</span>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dropdown">
              <button className="dropbtn">
                <div className="navbar-menu">Web Clones</div>
              </button>
              <div className="dropdown-content">
                <div className="nav_black_menu">
                  <div>
                    <h1 className="nav_list_text">Web Clones</h1>
                    <div className="navbar-wrapper">
                      {WebClonesData.map((item, index) => {
                        return (
                          <Link to={item?.Link} key={index} target="_blank">
                            <span className="linkH">{item?.SubTitle}</span>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dropdown">
              <button className="dropbtn">
                <div className="navbar-menu">App Clones</div>
              </button>
              <div className="dropdown-content">
                <div className="nav_black_menu">
                  <div>
                    <h1 className="nav_list_text">App Clones</h1>
                    <div className="navbar-wrapper">
                      {AppClonesData.map((item, index) => {
                        return (
                          <Link to={item?.Link} key={index} target="_blank">
                            <span className="linkH">{item?.SubTitle}</span>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dropdown">
              <button className="dropbtn">
                <div className="navbar-menu">On-demand</div>
              </button>
              <div className="dropdown-content">
                <div className="nav_black_menu">
                  <div>
                    <h1 className="nav_list_text">On-demand</h1>
                    <div className="navbar-wrapper">
                      {OnDemandData.map((item, index) => {
                        return (
                          <Link to={item?.Link} key={index} target="_blank">
                            <span className="linkH">{item?.SubTitle}</span>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="dropdown">
              <button className="dropbtn">
                <div className="navbar-menu">Hire Developers</div>
              </button>
              <div className="dropdown-content">
                <div className="nav_black_menu">
                  <div>
                    <h1 className="nav_list_text">Hire Developers</h1>
                    <div className="navbar-wrapper">
                      {HireDevelopersData.map((item, index) => {
                        return (
                          <Link to={item?.Link} key={index} target="_blank">
                            <span className="linkH">{item?.SubTitle}</span>
                          </Link>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="navbar-flex">
            <Link to={process.env.REACT_APP_BLOG_PRODUCT_URL} target="_blank">
              <button className="nav_white-btn">Buy Now</button>
            </Link>
            <button className="nav_black-btn" onClick={onTidioChatApiReady}>
              Live Chat
            </button>
          </div>
        </div>
        <button className="side-menu-btn" onClick={() => setMainMenuOpen(true)}>
          <img src={SIDE_MENU_ICON} alt="icon" className="side_img-size" />
        </button>
      </header>
      {/* ======================================================================= */}
      <aside className="side-menu-btn">
        <MainMenu mainMenuOpen={mainMenuOpen}>
          <section>
            <div className="side_menu-p">
              <div className="side_menu-title_icon">
                <span>Menu</span>
                <button onClick={() => setMainMenuOpen(false)}>
                  <img
                    src={SIDE_MENU_CLOSE}
                    alt="icon"
                    className="side_img-size"
                  />
                </button>
              </div>
              <div className="side-menu_scroll">
                {SideMenuData.map((item, index) => {
                  return (
                    <div key={index}>
                      <div
                        onClick={() => handleQueToggle(index)}
                        className={
                          expand ? "side_menu-list_" : "side_menu-list"
                        }
                      >
                        <span>{item?.Title}</span>
                        <img
                          src={SIDE_MENU_LIST_ICON}
                          alt="icon"
                          className={QueToggle(index) && `side_menu-list_icon`}
                          onClick={() => handleQueToggle(index)}
                        />
                      </div>
                      <div>
                        {QueToggle(index) &&
                          item?.List.map((items, idx) => {
                            return (
                              <div key={idx} className="side_menu_listing">
                                <Link
                                  onClick={() => setMainMenuOpen(false)}
                                  to={items?.Link}
                                  target={items?.TarGet && "_blank"}
                                >
                                  <span className="linkH">
                                    {items?.SubTitle}
                                  </span>
                                </Link>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="side_border_l"></div>
            <div>
              <div className="side_btn-fixed">
                <div className="side_btn_">
                  <Link
                    to={process.env.REACT_APP_BLOG_CONTACT_URL}
                    target="_blank"
                  >
                    <button className="side_black-btn">Contact Us</button>
                  </Link>
                  <button className="side_white-btn">Get A Quote</button>
                </div>
                <div className="side_menu_follo">
                  Follo Us - Dr. / Be. / Ig. / fb.{" "}
                </div>
              </div>
            </div>
          </section>
        </MainMenu>
      </aside>
    </>
  );
}

export default Navbar;

const SideMenuData = [
  {
    Title: "Ready to Use",
    List: [
      { SubTitle: "CRYPTO WALLET", Link: "/crypto-wallet", TarGet: false },
      { SubTitle: "DOCTOR ON DEMAND", Link: "/doctor-on-demand", TarGet: false },
      { SubTitle: "TIKTOK CLONE", Link: "/tiktok-clone", TarGet: false },
      { SubTitle: "INSTAGRAM CLONE", Link: "/instagram-clone", TarGet: false },
      { SubTitle: "FOOD RECIPE BOOK", Link: "/food-recipe-book", TarGet: false },
      { SubTitle: "CAR RENTAL", Link: "/car-rental-script-download", TarGet: false },
    ],
  },
  {
    Title: "Web Clones",
    List: [
  { SubTitle: "Etsy Clone", Link: process.env.REACT_APP_MENU_LIST_ETSY_CLONE_URL },
  { SubTitle: "Yelp Clone", Link: process.env.REACT_APP_MENU_LIST_YELP_CLONE_URL },
  { SubTitle: "OLX Clone", Link: process.env.REACT_APP_MENU_LIST_OLX_CLONE_URL },
  { SubTitle: "Lynda Clone", Link: process.env.REACT_APP_MENU_LIST_LYNDA_CLONE_URL },
  { SubTitle: "Fiverr Clone", Link: process.env.REACT_APP_MENU_LIST_FIVERR_CLONE_URL },
  { SubTitle: "Kohl's Clone", Link: process.env.REACT_APP_MENU_LIST_KOHLS_CLONE_URL },
  { SubTitle: "Netflix Clone", Link: process.env.REACT_APP_MENU_LIST_NETFLIX_CLONE_URL },
  { SubTitle: "Airbnb Clone", Link: process.env.REACT_APP_MENU_LIST_AIRBNB_CLONE_URL },
  { SubTitle: "Udemy Clone", Link: process.env.REACT_APP_MENU_LIST_UDEMY_CLONE_URL },
  { SubTitle: "Amazon Clone", Link: process.env.REACT_APP_MENU_LIST_AMAZON_CLONE_URL },
  { SubTitle: "Udacity Clone", Link: process.env.REACT_APP_MENU_LIST_UDACITY_CLONE_URL },
  { SubTitle: "Behance Clone", Link: process.env.REACT_APP_MENU_LIST_BEHANCE_CLONE_URL },
  { SubTitle: "Coursera Clone", Link: process.env.REACT_APP_MENU_LIST_COURSERA_CLONE_URL },
  { SubTitle: "Groupon Clone", Link: process.env.REACT_APP_MENU_LIST_GROUPON_CLONE_URL },
  { SubTitle: "Zomato Clone", Link: process.env.REACT_APP_MENU_LIST_ZOMATO_CLONE_URL },
  { SubTitle: "YouTube clone", Link: process.env.REACT_APP_MENU_LIST_YOUTUBE_CLONE__URLL},
  { SubTitle: "Skillshare Clone",Link: process.env.REACT_APP_MENU_LIST_SKILLSHARE_CLONE_URL,},
  { SubTitle: "99Designs Clone", Link: process.env.REACT_APP_MENU_LIST_99DESIGNS_CLONE_URL,},
  { SubTitle: "Taskrabbit Clone",Link: process.env.REACT_APP_MENU_LIST_TASKRABBIT_CLONE_URL,},
  { SubTitle: "Thumbtack Clone", Link: process.env.REACT_APP_MENU_LIST_THUMBTACK_CLONE_URL },
  { SubTitle: "Freelancer Clone",Link: process.env.REACT_APP_MENU_LIST_FREELANCER_CLONE_URL,},
  { SubTitle: "Eventbrite Clone",Link: process.env.REACT_APP_MENU_LIST_EVENTBRITE_CLONE_URL,},
  { SubTitle: "Kickstarter Clone",Link: process.env.REACT_APP_MENU_LIST_KICKSTARTER_CLONE_URL,},
  { SubTitle: "Khan Academy Clone",Link: process.env.REACT_APP_MENU_LIST_KHANACADEMY_CLONE_URL,},
  { SubTitle: "Teamtreehouse Clone",Link: process.env.REACT_APP_MENU_LIST_TEAMTREEHOUSE_CLONE_URL,},
    ],
  },
  {
    Title: "App Clones",
    List: [
      { SubTitle: "KIK Clone", Link: process.env.REACT_APP_MENU_LIST_KIK_CLONE_URL},
  { SubTitle: "POF Clone", Link: process.env.REACT_APP_MENU_LIST_POF_CLONE_URL},
  { SubTitle: "Viber Clone", Link: process.env.REACT_APP_MENU_LIST_VIBER_CLONE_URL},
  { SubTitle: "Repix Clone", Link: process.env.REACT_APP_MENU_LIST_REPIX_CLONE_URL},
  { SubTitle: "Lyft Clone", Link: process.env.REACT_APP_MENU_LIST_LYFT_CLONE_URL},
  { SubTitle: "Uber Clone", Link: process.env.REACT_APP_MENU_LIST_UBER_CLONE_URL},
  { SubTitle: "Tinder Clone", Link: process.env.REACT_APP_MENU_LIST_TINDER_CLONE_URL},
  { SubTitle: "Zocdoc Clone", Link: process.env.REACT_APP_MENU_LIST_ZOCDOC_CLONE_URL},
  { SubTitle: "JibJab Clone", Link: process.env.REACT_APP_MENU_LIST_JIBJAB_CLONE_URL},
  { SubTitle: "Spotify Clone", Link: process.env.REACT_APP_MENU_LIST_SPOTIFY_CLONE_URL},
  { SubTitle: "Magisto Clone", Link: process.env.REACT_APP_MENU_LIST_MAGISTO_CLONE_URL},
  { SubTitle: "Just Eat Clone", Link: process.env.REACT_APP_MENU_LIST_JUST_EAT_CLONE_URL},
  { SubTitle: "Whisper Clone", Link: process.env.REACT_APP_MENU_LIST_WHISPER_CLONE_URL},
  { SubTitle: "Yik Yak Clone", Link: process.env.REACT_APP_MENU_LIST_YIK_YAK_CLONE_URL},
  { SubTitle: "Taxi App Clone", Link: process.env.REACT_APP_MENU_LIST_TAXI_APP_CLONE_URL},
  { SubTitle: "Instagram Clone", Link: process.env.REACT_APP_MENU_LIST_INSTAGRAM_CLONE_URL},
  { SubTitle: "Snapchat Clone", Link: process.env.REACT_APP_MENU_LIST_SNAPCHAT_CLONE_URL},
  { SubTitle: "Dubsmash clone", Link: process.env.REACT_APP_MENU_LIST_DUBSMASH_CLONE_URL},
  { SubTitle: "FoodPanda Clone", Link: process.env.REACT_APP_MENU_LIST_FOODPANDA_CLONE_URL},
  { SubTitle: "Pop Messenger Clone", Link: process.env.REACT_APP_MENU_LIST_POP_MESSENGER_CLONE_URL},
  { SubTitle: "SoundCloud Clone", Link: process.env.REACT_APP_MENU_LIST_SOUNDCLOUD_CLONE_URL},
  { SubTitle: "Gojek Clone", Link: process.env.REACT_APP_MENU_LIST_GOJEK_CLONE_URL},
  { SubTitle: "Bigo Live Clone", Link: process.env.REACT_APP_MENU_LIST_BIGO_LIVE_CLONE_URL},
  { SubTitle: "Whatsapp Clone App", Link: process.env.REACT_APP_MENU_LIST_WHATSAPP_CLONE_URL},
  { SubTitle: "eBay Clone", Link: process.env.REACT_APP_MENU_LIST_EBAY_CLONE_URL},
  { SubTitle: "Trulia clone", Link: process.env.REACT_APP_MENU_LIST_TRULIA_CLONE_URL},
  { SubTitle: "TIK TOK clone", Link: process.env.REACT_APP_MENU_LIST_TIK_TOK_CLONE_URL},
  { SubTitle: "Zillow Clone", Link: process.env.REACT_APP_MENU_LIST_ZILLOW_CLONE_URL},
  { SubTitle: "Instacart Clone", Link: process.env.REACT_APP_MENU_LIST_INSTACART_CLONE_URL},
  { SubTitle: "Line Clone", Link: process.env.REACT_APP_MENU_LIST_LINE_CLONE_URL},
  { SubTitle: "Grofers Clone", Link: process.env.REACT_APP_MENU_LIST_GROFERS_CLONE_URL},
  { SubTitle: "BigBasket Clone", Link: process.env.REACT_APP_MENU_LIST_BIGBASKET_CLONE_URL},
  { SubTitle: "Coroflot Clone", Link: process.env.REACT_APP_MENU_LIST_COROFLOT_CLONE_URL},
    ],
  },
  {
    Title: "On-demand",
    List: [
      { SubTitle: "Cab Booking", Link: process.env.REACT_APP_MENU_LIST_CAB_BOOKING_URL},
      { SubTitle: "Fleet Management",Link: process.env.REACT_APP_MENU_LIST_FLEET_MANAGEMEN_URL},
      { SubTitle: "Limousine", Link: process.env.REACT_APP_MENU_LIST_LIMOUSINE_URL},
      { SubTitle: "E-Vehicle", Link: process.env.REACT_APP_MENU_LIST_E_VEHICLE_URL},
      { SubTitle: "Bicycle Sharing", Link: process.env.REACT_APP_MENU_LIST_BICYCLE_SHARING_URL},
      { SubTitle: "Car Rental", Link: process.env.REACT_APP_MENU_LIST_CAR_RENTAL_URL},
      { SubTitle: "Grocery", Link: process.env.REACT_APP_MENU_LIST_GROCERY_URL},
      { SubTitle: "Food", Link: process.env.REACT_APP_MENU_LIST_FOOD_URL},
      { SubTitle: "Liquor", Link: process.env.REACT_APP_MENU_LIST_LIQUOR_URL},
      { SubTitle: "Marijuana", Link: process.env.REACT_APP_MENU_LIST_MARIJUANA_URL},
      { SubTitle: "Flower", Link: process.env.REACT_APP_MENU_LIST_FLOWER_URL},
      { SubTitle: "Courier", Link: process.env.REACT_APP_MENU_LIST_COURIER_URL},
      { SubTitle: "Fuel", Link: process.env.REACT_APP_MENU_LIST_FUEL_URL},
      { SubTitle: "Handyman", Link: process.env.REACT_APP_MENU_LIST_HANDYMAN_URL},
      { SubTitle: "Laundry", Link: process.env.REACT_APP_MENU_LIST_LAUNDRY_URL},
      { SubTitle: "Maids", Link: process.env.REACT_APP_MENU_LIST_MAIDS_URL},
      { SubTitle: "Plumber", Link: process.env.REACT_APP_MENU_LIST_PLUMBER_URL},
      { SubTitle: "Tutors", Link: process.env.REACT_APP_MENU_LIST_TUTORS_URL},
      { SubTitle: "Carwash", Link: process.env.REACT_APP_MENU_LIST_CARWASH_URL},
      { SubTitle: "Doctor", Link: process.env.REACT_APP_MENU_LIST_DOCTOR_URL},
    ],
  },
  {
    Title: "Hire Developers",
    List: [
  { SubTitle: "Android", Link: process.env.REACT_APP_MENU_LIST_ANDROID_URL},
  { SubTitle: "iOS", Link: process.env.REACT_APP_MENU_LIST_IOS_URL},
  { SubTitle: "Flutter", Link: process.env.REACT_APP_MENU_LIST_FLUTTER_URL},
  { SubTitle: "Kotlin", Link: process.env.REACT_APP_MENU_LIST_KOTLIN_URL},
  { SubTitle: "NodeJS", Link: process.env.REACT_APP_MENU_LIST_NODEJS_URL},
  { SubTitle: "AngularJS", Link: process.env.REACT_APP_MENU_LIST_ANGULARJS_URL},
  { SubTitle: "ExpressJS", Link: process.env.REACT_APP_MENU_LIST_EXPRESSJS_URL},
  { SubTitle: "MongoDB", Link: process.env.REACT_APP_MENU_LIST_MONGODB_URL},
  { SubTitle: "ReactJS", Link: process.env.REACT_APP_MENU_LIST_REACTJS_URL},
  { SubTitle: "MERN Stack", Link: process.env.REACT_APP_MENU_LIST_MERN_STACK_URL},
  { SubTitle: "MEAN Stack", Link: process.env.REACT_APP_MENU_LIST_MEAN_STACK_URL},
  { SubTitle: "Smart Contract", Link: process.env.REACT_APP_MENU_LIST_SMART_CONTRACT_URL},
  { SubTitle: "Cryptocurrency", Link: process.env.REACT_APP_MENU_LIST_CRYPTOCURRENCY_URL},
  { SubTitle: "ICO", Link: process.env.REACT_APP_MENU_LIST_ICO_URL},
  { SubTitle: "Web3.JS", Link: process.env.REACT_APP_MENU_LIST_WEB3_JS_URL},
  { SubTitle: "Solidity", Link: process.env.REACT_APP_MENU_LIST_SOLIDITY_URL},
  { SubTitle: "Fullstack", Link: process.env.REACT_APP_MENU_LIST_FULLSTACK_URL},
  { SubTitle: "Quality Assurance",Link: process.env.REACT_APP_MENU_LIST_QUALITY_ASSURANC_URL},
  { SubTitle: "Frontend", Link: process.env.REACT_APP_MENU_LIST_FRONTEND_URL},
  { SubTitle: "UI/UX", Link: process.env.REACT_APP_MENU_LIST_UI_UX_URL},
    ],
  },
];

const WebClonesData = [
  { SubTitle: "Etsy Clone", Link: process.env.REACT_APP_MENU_LIST_ETSY_CLONE_URL },
  { SubTitle: "Yelp Clone", Link: process.env.REACT_APP_MENU_LIST_YELP_CLONE_URL },
  { SubTitle: "OLX Clone", Link: process.env.REACT_APP_MENU_LIST_OLX_CLONE_URL },
  { SubTitle: "Lynda Clone", Link: process.env.REACT_APP_MENU_LIST_LYNDA_CLONE_URL },
  { SubTitle: "Fiverr Clone", Link: process.env.REACT_APP_MENU_LIST_FIVERR_CLONE_URL },
  { SubTitle: "Kohl's Clone", Link: process.env.REACT_APP_MENU_LIST_KOHLS_CLONE_URL },
  { SubTitle: "Netflix Clone", Link: process.env.REACT_APP_MENU_LIST_NETFLIX_CLONE_URL },
  { SubTitle: "Airbnb Clone", Link: process.env.REACT_APP_MENU_LIST_AIRBNB_CLONE_URL },
  { SubTitle: "Udemy Clone", Link: process.env.REACT_APP_MENU_LIST_UDEMY_CLONE_URL },
  { SubTitle: "Amazon Clone", Link: process.env.REACT_APP_MENU_LIST_AMAZON_CLONE_URL },
  { SubTitle: "Udacity Clone", Link: process.env.REACT_APP_MENU_LIST_UDACITY_CLONE_URL },
  { SubTitle: "Behance Clone", Link: process.env.REACT_APP_MENU_LIST_BEHANCE_CLONE_URL },
  { SubTitle: "Coursera Clone", Link: process.env.REACT_APP_MENU_LIST_COURSERA_CLONE_URL },
  { SubTitle: "Groupon Clone", Link: process.env.REACT_APP_MENU_LIST_GROUPON_CLONE_URL },
  { SubTitle: "Zomato Clone", Link: process.env.REACT_APP_MENU_LIST_ZOMATO_CLONE_URL },
  { SubTitle: "YouTube clone", Link: process.env.REACT_APP_MENU_LIST_YOUTUBE_CLONE__URLL},
  { SubTitle: "Skillshare Clone",Link: process.env.REACT_APP_MENU_LIST_SKILLSHARE_CLONE_URL,},
  { SubTitle: "99Designs Clone", Link: process.env.REACT_APP_MENU_LIST_99DESIGNS_CLONE_URL,},
  { SubTitle: "Taskrabbit Clone",Link: process.env.REACT_APP_MENU_LIST_TASKRABBIT_CLONE_URL,},
  { SubTitle: "Thumbtack Clone", Link: process.env.REACT_APP_MENU_LIST_THUMBTACK_CLONE_URL },
  { SubTitle: "Freelancer Clone",Link: process.env.REACT_APP_MENU_LIST_FREELANCER_CLONE_URL,},
  { SubTitle: "Eventbrite Clone",Link: process.env.REACT_APP_MENU_LIST_EVENTBRITE_CLONE_URL,},
  { SubTitle: "Kickstarter Clone",Link: process.env.REACT_APP_MENU_LIST_KICKSTARTER_CLONE_URL,},
  { SubTitle: "Khan Academy Clone",Link: process.env.REACT_APP_MENU_LIST_KHANACADEMY_CLONE_URL,},
  { SubTitle: "Teamtreehouse Clone",Link: process.env.REACT_APP_MENU_LIST_TEAMTREEHOUSE_CLONE_URL,},
];

const AppClonesData = [
  { SubTitle: "KIK Clone", Link: process.env.REACT_APP_MENU_LIST_KIK_CLONE_URL},
  { SubTitle: "POF Clone", Link: process.env.REACT_APP_MENU_LIST_POF_CLONE_URL},
  { SubTitle: "Viber Clone", Link: process.env.REACT_APP_MENU_LIST_VIBER_CLONE_URL},
  { SubTitle: "Repix Clone", Link: process.env.REACT_APP_MENU_LIST_REPIX_CLONE_URL},
  { SubTitle: "Lyft Clone", Link: process.env.REACT_APP_MENU_LIST_LYFT_CLONE_URL},
  { SubTitle: "Uber Clone", Link: process.env.REACT_APP_MENU_LIST_UBER_CLONE_URL},
  { SubTitle: "Tinder Clone", Link: process.env.REACT_APP_MENU_LIST_TINDER_CLONE_URL},
  { SubTitle: "Zocdoc Clone", Link: process.env.REACT_APP_MENU_LIST_ZOCDOC_CLONE_URL},
  { SubTitle: "JibJab Clone", Link: process.env.REACT_APP_MENU_LIST_JIBJAB_CLONE_URL},
  { SubTitle: "Spotify Clone", Link: process.env.REACT_APP_MENU_LIST_SPOTIFY_CLONE_URL},
  { SubTitle: "Magisto Clone", Link: process.env.REACT_APP_MENU_LIST_MAGISTO_CLONE_URL},
  { SubTitle: "Just Eat Clone", Link: process.env.REACT_APP_MENU_LIST_JUST_EAT_CLONE_URL},
  { SubTitle: "Whisper Clone", Link: process.env.REACT_APP_MENU_LIST_WHISPER_CLONE_URL},
  { SubTitle: "Yik Yak Clone", Link: process.env.REACT_APP_MENU_LIST_YIK_YAK_CLONE_URL},
  { SubTitle: "Taxi App Clone", Link: process.env.REACT_APP_MENU_LIST_TAXI_APP_CLONE_URL},
  { SubTitle: "Instagram Clone", Link: process.env.REACT_APP_MENU_LIST_INSTAGRAM_CLONE_URL},
  { SubTitle: "Snapchat Clone", Link: process.env.REACT_APP_MENU_LIST_SNAPCHAT_CLONE_URL},
  { SubTitle: "Dubsmash clone", Link: process.env.REACT_APP_MENU_LIST_DUBSMASH_CLONE_URL},
  { SubTitle: "FoodPanda Clone", Link: process.env.REACT_APP_MENU_LIST_FOODPANDA_CLONE_URL},
  { SubTitle: "Pop Messenger Clone", Link: process.env.REACT_APP_MENU_LIST_POP_MESSENGER_CLONE_URL},
  { SubTitle: "SoundCloud Clone", Link: process.env.REACT_APP_MENU_LIST_SOUNDCLOUD_CLONE_URL},
  { SubTitle: "Gojek Clone", Link: process.env.REACT_APP_MENU_LIST_GOJEK_CLONE_URL},
  { SubTitle: "Bigo Live Clone", Link: process.env.REACT_APP_MENU_LIST_BIGO_LIVE_CLONE_URL},
  { SubTitle: "Whatsapp Clone App", Link: process.env.REACT_APP_MENU_LIST_WHATSAPP_CLONE_URL},
  { SubTitle: "eBay Clone", Link: process.env.REACT_APP_MENU_LIST_EBAY_CLONE_URL},
  { SubTitle: "Trulia clone", Link: process.env.REACT_APP_MENU_LIST_TRULIA_CLONE_URL},
  { SubTitle: "TIK TOK clone", Link: process.env.REACT_APP_MENU_LIST_TIK_TOK_CLONE_URL},
  { SubTitle: "Zillow Clone", Link: process.env.REACT_APP_MENU_LIST_ZILLOW_CLONE_URL},
  { SubTitle: "Instacart Clone", Link: process.env.REACT_APP_MENU_LIST_INSTACART_CLONE_URL},
  { SubTitle: "Line Clone", Link: process.env.REACT_APP_MENU_LIST_LINE_CLONE_URL},
  { SubTitle: "Grofers Clone", Link: process.env.REACT_APP_MENU_LIST_GROFERS_CLONE_URL},
  { SubTitle: "BigBasket Clone", Link: process.env.REACT_APP_MENU_LIST_BIGBASKET_CLONE_URL},
  { SubTitle: "Coroflot Clone", Link: process.env.REACT_APP_MENU_LIST_COROFLOT_CLONE_URL},
];

const OnDemandData = [
  { SubTitle: "Cab Booking", Link: process.env.REACT_APP_MENU_LIST_CAB_BOOKING_URL},
  { SubTitle: "Fleet Management",Link: process.env.REACT_APP_MENU_LIST_FLEET_MANAGEMEN_URL},
  { SubTitle: "Limousine", Link: process.env.REACT_APP_MENU_LIST_LIMOUSINE_URL},
  { SubTitle: "E-Vehicle", Link: process.env.REACT_APP_MENU_LIST_E_VEHICLE_URL},
  { SubTitle: "Bicycle Sharing", Link: process.env.REACT_APP_MENU_LIST_BICYCLE_SHARING_URL},
  { SubTitle: "Car Rental", Link: process.env.REACT_APP_MENU_LIST_CAR_RENTAL_URL},
  { SubTitle: "Grocery", Link: process.env.REACT_APP_MENU_LIST_GROCERY_URL},
  { SubTitle: "Food", Link: process.env.REACT_APP_MENU_LIST_FOOD_URL},
  { SubTitle: "Liquor", Link: process.env.REACT_APP_MENU_LIST_LIQUOR_URL},
  { SubTitle: "Marijuana", Link: process.env.REACT_APP_MENU_LIST_MARIJUANA_URL},
  { SubTitle: "Flower", Link: process.env.REACT_APP_MENU_LIST_FLOWER_URL},
  { SubTitle: "Courier", Link: process.env.REACT_APP_MENU_LIST_COURIER_URL},
  { SubTitle: "Fuel", Link: process.env.REACT_APP_MENU_LIST_FUEL_URL},
  { SubTitle: "Handyman", Link: process.env.REACT_APP_MENU_LIST_HANDYMAN_URL},
  { SubTitle: "Laundry", Link: process.env.REACT_APP_MENU_LIST_LAUNDRY_URL},
  { SubTitle: "Maids", Link: process.env.REACT_APP_MENU_LIST_MAIDS_URL},
  { SubTitle: "Plumber", Link: process.env.REACT_APP_MENU_LIST_PLUMBER_URL},
  { SubTitle: "Tutors", Link: process.env.REACT_APP_MENU_LIST_TUTORS_URL},
  { SubTitle: "Carwash", Link: process.env.REACT_APP_MENU_LIST_CARWASH_URL},
  { SubTitle: "Doctor", Link: process.env.REACT_APP_MENU_LIST_DOCTOR_URL},
];

const HireDevelopersData = [
  { SubTitle: "Android", Link: process.env.REACT_APP_MENU_LIST_ANDROID_URL},
  { SubTitle: "iOS", Link: process.env.REACT_APP_MENU_LIST_IOS_URL},
  { SubTitle: "Flutter", Link: process.env.REACT_APP_MENU_LIST_FLUTTER_URL},
  { SubTitle: "Kotlin", Link: process.env.REACT_APP_MENU_LIST_KOTLIN_URL},
  { SubTitle: "NodeJS", Link: process.env.REACT_APP_MENU_LIST_NODEJS_URL},
  { SubTitle: "AngularJS", Link: process.env.REACT_APP_MENU_LIST_ANGULARJS_URL},
  { SubTitle: "ExpressJS", Link: process.env.REACT_APP_MENU_LIST_EXPRESSJS_URL},
  { SubTitle: "MongoDB", Link: process.env.REACT_APP_MENU_LIST_MONGODB_URL},
  { SubTitle: "ReactJS", Link: process.env.REACT_APP_MENU_LIST_REACTJS_URL},
  { SubTitle: "MERN Stack", Link: process.env.REACT_APP_MENU_LIST_MERN_STACK_URL},
  { SubTitle: "MEAN Stack", Link: process.env.REACT_APP_MENU_LIST_MEAN_STACK_URL},
  { SubTitle: "Smart Contract", Link: process.env.REACT_APP_MENU_LIST_SMART_CONTRACT_URL},
  { SubTitle: "Cryptocurrency", Link: process.env.REACT_APP_MENU_LIST_CRYPTOCURRENCY_URL},
  { SubTitle: "ICO", Link: process.env.REACT_APP_MENU_LIST_ICO_URL},
  { SubTitle: "Web3.JS", Link: process.env.REACT_APP_MENU_LIST_WEB3_JS_URL},
  { SubTitle: "Solidity", Link: process.env.REACT_APP_MENU_LIST_SOLIDITY_URL},
  { SubTitle: "Fullstack", Link: process.env.REACT_APP_MENU_LIST_FULLSTACK_URL},
  { SubTitle: "Quality Assurance",Link: process.env.REACT_APP_MENU_LIST_QUALITY_ASSURANC_URL},
  { SubTitle: "Frontend", Link: process.env.REACT_APP_MENU_LIST_FRONTEND_URL},
  { SubTitle: "UI/UX", Link: process.env.REACT_APP_MENU_LIST_UI_UX_URL},
];

const ReadyUseData = [
  { SubTitle: "CRYPTO WALLET", Link: "/crypto-wallet" },
  { SubTitle: "DOCTOR ON DEMAND", Link: "/doctor-on-demand" },
  { SubTitle: "TIKTOK CLONE", Link: "/tiktok-clone" },
  { SubTitle: "INSTAGRAM CLONE", Link: "/instagram-clone" },
  { SubTitle: "FOOD RECIPE BOOK", Link: "/food-recipe-book" },
  { SubTitle: "CAR RENTAL", Link: "/car-rental-script-download" },
];