import { IS_PANEL_VECTOR } from "../../assets";

function ISWebPanel() {
  return (
    <main className="WebPanel-container">
      <div className="WebPanel-title">Instagram Clone Web Panel</div>
      <div className="cwa-subtitle">
        Our Instagram clone is fully customizable, allowing you to modify the
        app to suit your specific business needs. You can add your own branding,
        change the app's theme and color scheme, and even add new features to
        the platform.
      </div>
      <img src={IS_PANEL_VECTOR} alt="icon" className="webpanel-img" />
    </main>
  );
}

export default ISWebPanel;
