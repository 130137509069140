import {
  BALANCE_ICON,
  BLOACK_CHIAN_ICON,
  COIN_ICON,
  SECURE_WALLET_ICON,
  SEND_RECIEVE_ICON,
  TOKEN_ICON,
} from "../../assets";

function AboutFeatures() {
  return (
    <main className="AboutFeatures-container">
      <div className="AboutFeatures-title">Crypto Wallet Advance Features</div>
      <div>
        <div className="AboutFeatures-wrapper">
          {AboutFeaturesData.map((item, index) => {
            return (
              <div className="AboutFeatures-box" key={index}>
                <div className="AboutFeatures-card">
                  <div className="AboutFeatures-img-flex">
                    <div className="AboutFeatures-img-box">
                      <img
                        src={item?.IMG}
                        alt="icon"
                        className="AboutFeatures-img_"
                      />
                    </div>
                    <div className="AboutFeatures-subtitle ">{item?.Title}</div>
                  </div>
                  <div className="AboutFeatures-info">{item?.describe}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </main>
  );
}
export default AboutFeatures;

const AboutFeaturesData = [
  {
    IMG: SECURE_WALLET_ICON,
    Title: "Secure Wallet",
    describe:
      "Security is a top priority when it comes to managing your digital assets. With Skyclones' Crypto Wallet, you can rest assured that your cryptocurrency is stored safely and securely. Our wallet is built with the latest security features, including two-factor authentication and encryption, to protect your funds from potential threats. With our wallet, you can manage multiple cryptocurrencies with peace of mind, knowing that your assets are in good hands.",
  },
  {
    IMG: BALANCE_ICON,
    Title: "Balance and transactions",
    describe:
      "With Skyclones' Crypto Wallet, you can easily manage your cryptocurrency balances and transactions all in one place. Our wallet provides a clear overview of your balances across multiple currencies, making it easy to track your investments and make informed decisions. You can also view your transaction history in real-time, so you always know where your funds are going. Plus, with our intuitive user interface, managing your balances and transactions has never been easier.",
  },
  {
    IMG: COIN_ICON,
    Title: "Multiple coins and balance ",
    describe:
      "Skyclones' Crypto Wallet supports multiple cryptocurrencies, so you can manage all of your digital assets in one place. With support for popular currencies like Bitcoin, Ethereum, and Litecoin, as well as a range of other altcoins, our wallet provides the flexibility you need to manage your investments. Plus, with a clear overview of your balances across multiple currencies, you can easily monitor your portfolio and make informed decisions about your investments. Whether you're a seasoned investor or just getting started with cryptocurrency, our multi-currency wallet has you covered.",
  },
  {
    IMG: TOKEN_ICON,
    Title: "Custom Crypto token",
    describe:
      "With Skyclones' Crypto Wallet, you can easily manage custom crypto tokens alongside popular cryptocurrencies. Our wallet provides support for a wide range of tokens, so you can manage all of your digital assets in one place. Whether you're investing in a new ICO or creating your own custom token, our wallet provides the flexibility you need to manage your investments. With a clear overview of your custom tokens and their values, you can stay on top of your investments and make informed decisions about your portfolio.",
  },
  {
    IMG: SEND_RECIEVE_ICON,
    Title: "Send/Recieve Crypto",
    describe:
      "With Skyclones' Crypto Wallet, you can easily send and receive cryptocurrency from anywhere in the world. Our wallet provides a simple and intuitive interface for managing your transactions, making it easy to send and receive payments in just a few clicks. Plus, with support for a wide range of popular cryptocurrencies, you can easily transact with anyone, anywhere. Whether you're sending funds to a friend or receiving payment for goods and services, our wallet provides the flexibility and convenience you need to manage your digital assets.",
  },
  {
    IMG: BLOACK_CHIAN_ICON,
    Title: "block chains",
    describe:
      "Skyclones' Crypto Wallet is built on the latest blockchain technology, providing you with a secure and transparent way to manage your digital assets. Our wallet uses distributed ledger technology to ensure that your transactions are secure and verifiable, with every transaction recorded on the blockchain for transparency and accountability. With blockchain technology, you can have confidence that your funds are being managed securely and that your transactions are processed quickly and efficiently. Plus, with support for a wide range of blockchain networks, our wallet provides the flexibility you need to manage your digital assets on the blockchain of your choice.",
  },
];
