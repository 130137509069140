import { Link } from "react-router-dom";
import { TIK_MAIN_VECTOR } from "../../assets";

function TIKCrateWallet() {
  return (
    <main className="TIK_main-container ">
      <section className="webapp-flex">
        <div className="webapp-flex-col">
          <span className="webapp-title">Tiktok Readymade Script</span>
          <span className="webapp-sub-title">
            Skyclones offers a ready-to-use TikTok clone script for Android and
            iOS, designed to help entrepreneurs and businesses quickly launch
            their own social video app. Our TikTok clone is built using the
            latest technologies, and features a user-friendly interface,
            customizable design, and robust functionality. With our TikTok
            clone, you can easily create and share short videos, add effects and
            filters, follow other users, and much more. The app also comes with
            a powerful admin panel, allowing you to manage your content, users,
            and app settings with ease. Best of all, our TikTok clone is
            available at an affordable price, making it a great choice for
            startups and small businesses looking to enter the social video
            market. Get started today and bring your TikTok clone app to life
            with Skyclones.
          </span>
          <div className="btn-flex">
            <Link to={process.env.REACT_APP_BLOG_TIKTIK_URL} target="_blank">
              <button className="red-btn-small">
                <div className="btn_align">Buy Now</div>
              </button>
            </Link>
            <Link to={process.env.REACT_APP_PLAY_STORE_TIKTIK_URL} target="_blank">
              <button className="white-btn-small">
                <div className="btn_align">Free Download</div>
              </button>
            </Link>
          </div>
        </div>
        <div>
          <img
            src={TIK_MAIN_VECTOR}
            alt="vector"
            className="cryptowallet-img"
          />
        </div>
      </section>
    </main>
  );
}

export default TIKCrateWallet;
