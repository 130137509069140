import { useState } from "react";
import { CLOSE_RED_ICON, OPEN_BLACK_ICON } from "../../assets";

function FAQs() {
  const [expand, setExpand] = useState([]);
  const handleQueToggle = (idx) => {
    if (expand.includes(idx)) setExpand(expand.filter((i) => i !== idx));
    else setExpand([...expand, idx]);
  };
  const QueToggle = (i) => expand.includes(i);
  return (
    <main className="FAQs-container FAQs_BG">
      <section className="FAQs-flex_">
        <div className="FAQs-Title">FAQs</div>
        {FAQsData.map((item, index) => {
          return (
            <div className="FAQs-bg" key={index}>
              <div className="FAQs-flex" onClick={() => handleQueToggle(index)}>
                <span className="FAQs-que">
                  <span>{index + 1}.</span>
                  <span style={{ marginLeft: "10px" }}> {item?.Que}</span>
                </span>
                <img
                  src={QueToggle(index) ? CLOSE_RED_ICON : OPEN_BLACK_ICON}
                  alt="iocn"
                />
              </div>
              {QueToggle(index) && (
                <div className="FAQs-ans-bg">
                  <span className="FAQs-que">{item?.Letter}</span>{" "}
                  <span className="FAQs-ans">{item?.Ans}</span>
                </div>
              )}
            </div>
          );
        })}
      </section>
    </main>
  );
}

export default FAQs;

const FAQsData = [
  {
    Que: "What is Skyclones?",
    Letter: "A.",
    Ans: "Skyclones is an on-demand application development company that offers a range of solutions to help businesses create better mobile apps quickly and easily. We offer a variety of products and services, including readymade scripts, UI controls, and custom application development.",
  },
  {
    Que: "What kind of products does Skyclones offer?",
    Letter: "A.",
    Ans: "Skyclones offers a range of products to help businesses create better mobile apps, including readymade scripts for social media platforms like TikTok and Instagram, UI controls, and tools for converting e-commerce websites to mobile apps.",
  },
  {
    Que: "Are Skyclones' products customizable?",
    Letter: "A.",
    Ans: " Yes! All of Skyclones' products are customizable to fit the unique needs of your business. Whether you're looking to add new features to an existing product or create a completely custom solution, our team of experts can help.",
  },
  {
    Que: "What kind of businesses can benefit from Skyclones' solutions?",
    Letter: "A.",
    Ans: "Skyclones' solutions are designed to benefit businesses of all sizes and backgrounds, from startups to large enterprises. Whether you're just starting out or looking to scale your existing business, our solutions can help you create better mobile apps quickly and cost-effectively.",
  },
  {
    Que: "How can I get started with Skyclones' solutions?",
    Letter: "A.",
    Ans: "Getting started with Skyclones is easy! Simply browse our range of products and services on our website, and reach out to our team for a consultation. We'll work with you to understand your unique needs and create a customized solution that fits your business.",
  },
];
