import React, { Fragment } from "react";
import { AboutFeature, AboutUS, AppOverviewDesk, BusinessInfo, DownloadSection, Feedback, Footer, Layout, MainPage, Technologie, VideoPlay, WebPanels } from "../components";
import { BNB_PHONE1, BNB_WA_VECTOR, CR_ABOUR_VECTOR, CR_AI_VECTOR, CR_BLOGS_ICON, CR_BOOKING_ICON, CR_MAIN_VECTOR, CR_OUR_CARS_ICON, CR_OUR_EX_ICON, CR_SERVICES_ICON, CR_TESTIMONIALS_ICON, CR_WEB_DASH, DEDICATED_VECTOR, T_CSS_ICON, T_HTML_ICON, T_JS_ICON, T_LARAVEL_ICON, T_PHP_ICON } from "../assets";

function CarRental() {
    return (
        <Fragment>
            {DynamicData.map((item, index) => {
                return (
                    <div key={index}>
                        <Layout
                            title={item?.title}
                            metaTitle={item?.metaTitle}
                            metaDesc={item?.metaDesc}
                            metaKey={item?.metaKey}
                        />
                        <MainPage mainPage={item?.MainPage} />
                        <AboutUS aboutus={item?.Aboutus} />
                        <AboutFeature aboutFeatures={item?.AboutFeatures} />
                        <AppOverviewDesk appOverview={item?.AppOverview} />
                        <WebPanels webPanel={item?.webPabel} />
                        <VideoPlay videoPlay={item?.VideoPlay} />
                        <Technologie technologie={item?.Technologie} />
                        <DownloadSection downloadSection={item?.DownloadSection} />
                        <BusinessInfo businessInfo={item?.BusinessInfo} />
                        <Feedback />
                        <Footer />
                    </div>
                )
            })}
        </Fragment>
    )
}

export default CarRental

const DynamicData = [
    {
        title: "Car Rental Script - Start Your Car Rental Business at $99 | Skyclones",
        metaTitle: "Car Rental Script - Start Your Car Rental Business at $99 | Skyclones",
        metaDesc: "Launch your car rental platform with our Car Rental Script for just $99. Explore powerful features, responsive design, and expert support. Download now!",
        metaKey: "Car Rental Script, Car Rental Business, Rental Platform, Car Rental Software, Skyclones, Clone Script, Car Rental Website, Vehicle Rental Script",
        MainPage: {
            backgroundImage: "images/CR-BG.svg",
            title: "Get Your Car Rental Script at Just $99 - Download Now!",
            subtitle: "Are you ready to revolutionize the car rental industry? Skyclones presents an incredible opportunity for you to start your own car rental platform with our Car Rental Script, priced at an unbeatable $99!",
            btnName: "Let's Talk",
            buyNow: process.env.REACT_APP_BLOG_CAR_RENTAL_URL,
            freeDown: "",
            vector: CR_MAIN_VECTOR,
        },
        Aboutus: {

            backgroundColor: "#FFF4DB",
            vector: CR_ABOUR_VECTOR,
            aboutTextcolor: "#FFC001",
            aboutTagline: "ABOUT US",
            title: "About Skyclones",
            subtitle: "At Skyclones, we're committed to providing cutting-edge solutions for entrepreneurs and businesses looking to enter the car rental market. With years of experience and a team of talented developers, we've established ourselves as a trusted name in the world of clone scripts and app development.",
            buyLink: process.env.REACT_APP_BLOG_CAR_RENTAL_URL,
            freeDownloadLink: "",
            // iosAppLink: process.env.REACT_APP_IOS_BNB_URL,
            // androidAppLink: process.env.REACT_APP_PLAY_STORE_BNB_URL,
            webLink: process.env.REACT_APP_WEB_CAR_RENTAL_URL,
        },
        AboutFeatures: {
            backgroundImage: "images/Features-BG-BNB.svg",
            title: "Key Features of Our Car Rental Script",
            Features: [
                {
                    IMG: CR_OUR_CARS_ICON,
                    Title: "User-Friendly Interface",
                    describe:
                        "Our script offers an intuitive user experience for both renters and car owners, ensuring easy navigation.",
                },
                {
                    IMG: CR_BOOKING_ICON,
                    Title: "Advanced Booking System",
                    describe:
                        "Streamline reservations with a powerful booking system that includes real-time availability.",
                },
                {
                    IMG: CR_BLOGS_ICON,
                    Title: "Rental Fleet Management",
                    describe:
                        "Easily manage your fleet, including vehicle profiles, availability, and pricing.",
                },
                {
                    IMG: CR_SERVICES_ICON,
                    Title: "Secure Payment Processing",
                    describe:
                        "We integrate secure payment gateways, giving your users peace of mind.",
                },
                {
                    IMG: CR_OUR_EX_ICON,
                    Title: "Mobile Accessibility",
                    describe:
                        "Our script is fully responsive, ensuring your platform works flawlessly on mobile devices.",
                },
                {
                    IMG: CR_TESTIMONIALS_ICON,
                    Title: "Ratings and Reviews",
                    describe:
                        "Encourage trust and transparency with a ratings and reviews system.",
                },
            ]
        },
        AppOverview: {
            title: "How to Buy Our Car Rental Script",
            subtitle: `Purchasing our Car Rental Script is a breeze. Simply visit our website, browse the product page, and click the "Buy Now" button. You'll be guided through a secure payment process, and once completed, you'll receive instant access to the script.`,
            vector: BNB_WA_VECTOR,
            vector1: [
                {
                    link: BNB_PHONE1,
                },
                {
                    link: BNB_PHONE1,
                },
                {
                    link: BNB_PHONE1,
                },
                {
                    link: BNB_PHONE1,
                },
                {
                    link: BNB_PHONE1,
                },
            ]
        },
        webPabel: {
            title: "Car Rental Script Web Panel",
            subtitle: "Manage your car rental business efficiently with our web panel. Our user-friendly interface allows you to oversee bookings, monitor your fleet, and handle payments effortlessly.",
            panelImg: CR_WEB_DASH,
        },
        VideoPlay: {
            backgroundImage: "images/BNB_youtube_Bg.svg",
            title: "Free Demo Video",
            subtitle: "See our Car Rental Script in action with our free demo video. Experience the seamless booking process, user-friendly interface, and robust features that await you.",
            videoLink: process.env.REACT_APP_YOUTUBE_CAR_RENTAL_URL,
        },
        Technologie: {
            title: "Technologies",
            subtitle: "Our Car Rental Script is built using the latest technologies, ensuring a seamless and responsive user experience. We leverage the power of React Native for mobile responsiveness and PHP for robust web functionality.",
            icon1: T_PHP_ICON,
            title1: "PHP",
            icon2: T_LARAVEL_ICON,
            title2: "laravel",
            icon3: T_JS_ICON,
            title3: "javascript",
            icon4: T_HTML_ICON,
            title4: "HTML",
            icon5: T_CSS_ICON,
            title5: "CSS",
        },
        DownloadSection: {
            title: "Download Car Rental Script Now",
            subtitle: "Take the leap and download our Car Rental Script today. Join the ranks of successful car rental entrepreneurs who have chosen Skyclones for their business ventures.",
            GooglePlayLink: "",
            AppStoreLink: "",
            webLiveLink: process.env.REACT_APP_WEB_CAR_RENTAL_URL,
            vector: CR_AI_VECTOR,
        },
        BusinessInfo: {
            vector: DEDICATED_VECTOR,
            title: "What Our Clients Say",
            subtitle: `"Skyclones has been a game-changer for our car rental business. The Car Rental Script is feature-rich, easy to use, and the support from the Skyclones team has been exceptional." - John Doe, CEO of RentalXpress.`,
        }
    }
]